class PlaylistDataService {
    async getAll() {
        const response = await fetch('/api/playlist/');
        return await response.json();
    }

    async get(id) {
        const response = await fetch("/api/playlist/getplaylist?id=" + id);
        return await response.json();
    }

    async getBymember(memberId) {
        console.log(memberId);
        const response = await fetch("/api/playlist/getbymember?memberId=" + memberId);
        return await response.json();
    } 

    async getByUser(token) {
        console.log(token);
        const response = await fetch("/api/playlist/getbyuser", { headers: { 'Authorization': `Bearer ${token}` } });
        return await response.json();
    }

    async createPlaylist(name, token) {
        const response = await fetch("/api/playlist/create?name=" + name, { headers: { 'Authorization': `Bearer ${token}` } });
        return await response.json();
    }

    async removePlaylist(playlistId, token) {
        const response = await fetch("/api/playlist/remove?playlistId=" + playlistId, { headers: { 'Authorization': `Bearer ${token}` } });
        return await response.json();
    }

    async updatePlaylist(playlistId, name, token) {
        const response = await fetch("/api/playlist/update?playlistId=" + playlistId + "&name=" + name, { headers: { 'Authorization': `Bearer ${token}` } });
        return await response.json();
    }

    async addVideo(playlistId, videoId, token) {
        const response = await fetch("/api/playlist/addvideo?playlistId=" + playlistId + "&videoId=" + videoId, { headers: { 'Authorization': `Bearer ${token}` } });
        return await response.json();
    }

    async removeVideo(playlistId, videoId, token) {
        const response = await fetch("/api/playlist/removevideo?playlistId=" + playlistId + "&videoId=" + videoId, { headers: { 'Authorization': `Bearer ${token}` } });
        return await response.json();
    }
}

export default new PlaylistDataService();