import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import PlaylistService from "../services/Playlist.service";
import FavouriteService from "../services/Favourite.service";
import { Nav, NavItem, NavLink, TabContent, TabPane, Spinner } from 'reactstrap';
import { AdListing } from "../components/AdListing";

function Profile_Page(props) {

    const params = useParams();

    const [activeTab, setActiveTab] = useState('1');
    const [memberPlaylists, setMemberPlaylists] = useState([]);
    const [memberFavourites, setMemberFavourites] = useState('');
    const [loadingMemberPlaylists, setLoadingMemberPlaylists] = useState(true);
    const [loadingMemberFavourites, setLoadingMemberFavourites] = useState(true);

    useEffect(() => {
        loadMemberPlayLists(params.memberId);
        loadMemberFavourites(params.memberId);
    }, []);

    async function loadMemberPlayLists(memberId) {
        const data = await PlaylistService.getBymember(memberId);
        setMemberPlaylists(data);
        setLoadingMemberPlaylists(false);
    }

    async function loadMemberFavourites(memberId) {
        const data = await FavouriteService.getBymember(memberId);
        setMemberFavourites(data);
        setLoadingMemberFavourites(false);
    }

    function renderMemberPlaylists(memberPlaylists) {
        return (
            <div >
                {memberPlaylists.filter(x => x.playlistMovies.length > 4).map(playlist =>
                    <div key={"playlistHolder_" + playlist.id}>
                        <h4>{playlist.name}</h4>
                        <div className="cards" key={"playlistPreviewHolder_" + playlist.id}>

                            {playlist.playlistMovies.slice(0, 5).map(plEntity =>
                                <div key={"playlistPreviewCard_" + plEntity.movie.id} className="card cursor" alt="View playlist" title="View playlist" onClick={() => document.location.href = "/playlist/" + playlist.id} style={{ backgroundImage: `url(${plEntity.movie.thumbnail})` }}>
                                    <span className="fa-stack fa-3x">
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i id="icon" className="fas fa-robot fa-stack-1x fa-inverse"></i>
                                    </span>
                                </div>
                            )}

                        </div>
                        <br />
                    </div>
                )}
            </div>
        );
    }

    function renderMemberFavourites(memberFavourites) {
        return (
            <div className="row">
                {memberFavourites.map(fave =>
                    <div className="col-sm-2 col-6" key={"movieHolder_" + fave.movie.wmcExternalId}>
                        <div className="divMovieListing" key={"movieCard_" + fave.movie.wmcExternalId}>
                            <a href={"/video/" + fave.movie.wmcExternalId + "/" + fave.movie.title} className="cursor">
                                <img title={fave.movie.title} alt={fave.movie.title} src={fave.movie.thumbnail} id={"thumb_" + fave.movie.wmcExternalId} className="imgThumb" />
                            </a>
                            <div className="divMovieListingInfo">
                                <div className="row">
                                    <div className="col-12"><a href={"/video/" + fave.movie.wmcExternalId + "/" + fave.movie.title} className="cursor">{fave.movie.title}</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-sm-9 col-12">
                <h2>{params.memberName}'s crib</h2>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={activeTab === '1' ? "active profileTab cursor" : " profileTab cursor"}
                            onClick={() => { setActiveTab('1'); }}>
                            Playlists
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === '2' ? "active profileTab cursor" : " profileTab cursor"}
                            onClick={() => { setActiveTab('2'); }}>
                            Favourites
                        </NavLink>
                    </NavItem>                   
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <h3>Playlists</h3>
                        <p>Here you have {params.memberName}'s playlists!</p>
                        {loadingMemberPlaylists ? <div><Spinner className="sm" /> Loading member playlists</div> : renderMemberPlaylists(memberPlaylists)}
                    </TabPane>
                    <TabPane tabId="2">
                        <h3>Favourites</h3>
                        <p>Here you have {params.memberName}'s favourite vids!</p>
                        {loadingMemberFavourites ? <div><Spinner className="sm" /> Loading member favourites</div> : renderMemberFavourites(memberFavourites)}
                    </TabPane>
                    <TabPane tabId="3">
                        <h3>Comments</h3>
                       Comments
                    </TabPane>
                </TabContent>
                
            </div>
            <div className="col-sm-3 col-12">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>      
        </div>
    );
}

export { Profile_Page };