import React, { useEffect, useState } from 'react';
import PlaylistService from "../services/Playlist.service"
import authService from './../components/api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Label, FormGroup, FormFeedback, FormText } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { AdListing } from "../components/AdListing";

function MyPlaylists_Page(props) {

    const [authenticated, setAuthenticated] = useState([false]);
    const [playlist, setPlaylist] = useState([]);
    const [myPlaylists, setMyPlaylists] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const toggle = () => setModal(!modal);
    const toggleEdit = () => setModalEdit(!modalEdit);
    const [playlistName, setPlaylistName] = useState('');

    useEffect(() => {
        populateState();
        loadMyPlayLists();
    }, []);

    async function loadMyPlayLists() {
        const token = await authService.getAccessToken();
        if (token !== null) {
            const data = await PlaylistService.getByUser(token);
            setMyPlaylists(data);
        }
    }

    async function editPlaylist(playlistId) {
        const token = await authService.getAccessToken();
        const data = await PlaylistService.get(playlistId, token);
        setPlaylist(data);
        setPlaylistName(data.name);
        toggleEdit();
    }

    async function populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        if (user) {            
            setAuthenticated(isAuthenticated);
        }
    }

    function returnRegisterBox() {
        return <div><h4>As a member you can manage your very own playlists!</h4>
            <h4>Membership Perks</h4>
            Why join you ask!
            <ul>
                <li>Because...it's cool...obviously!</li>
                <li>Manage your own playlists</li>
                <li>Rate our videos</li>
                <li>Have your own favebox with favourite videos</li>
                <li>Comment videos together with other peeps</li>
                <li>Check out your very own toplists</li>
                <li>Premium Content</li>
                <li>Oh so much more to come!</li>                
            </ul><h4><a href="/authentication/register">Sign up here to join our merry gang!</a></h4></div>
    }

    function returnMyPlayLists() {
        return <div className="row">
                <div className="col-12 rightAligned"><button className="btn-primary" onClick={() => toggle()}>Create + </button></div>
               </div>
    }

    async function removePlaylist(playlistId) {
        const token = await authService.getAccessToken();
        if (!window.confirm("Are you sure you want to remove this playlist?")) {
            return false;
        }
        await PlaylistService.removePlaylist(playlistId, token);
        loadMyPlayLists();
        toast("Playlist removed!")
    }
        
    async function createPlayList() {
        toggle();
        const name = document.getElementById("playlistName").value;
        const token = await authService.getAccessToken();
        await PlaylistService.createPlaylist(name, token);
        loadMyPlayLists();
        toast("Playlist created!")
    }

    async function updatePlayList() {
        toggleEdit();
        const token = await authService.getAccessToken();
        await PlaylistService.updatePlaylist(playlist.id, playlistName, token);
        loadMyPlayLists();
        toast("Playlist updated!")
    }

    function renderPlaylistsTable(thesePlaylists) {
        return (
            <div >
                {thesePlaylists.filter(x => x.playlistMovies.length>4).map(playlist =>
                    <div key={"playlistHolder_" + playlist.id}>
                        <div className="row">
                            <div className="col-sm-8"><h4>{playlist.name}</h4></div>
                            <div className="col-sm-4 rightAligned"><button className="btn-primary" key={"playlistbtnAdd" + playlist.id} onClick={() => editPlaylist(playlist.id)}>Tweak it</button> &nbsp;
                                <button className="btn-primary" key={"playlistbtnRemove" + playlist.id} onClick={() => removePlaylist(playlist.id)}>Kill it</button></div>
                        </div>                        
                            <div className="cards" key={"playlistPreviewHolder_" + playlist.id}>
                                
                                    {playlist.playlistMovies.slice(0, 5).map(plEntity =>                                        
                                        <div key={"playlistPreviewCard_" + plEntity.movie.id} className="card cursor" alt="View playlist" title="View playlist" onClick={() => document.location.href = "/playlist/" + playlist.id} style={{backgroundImage: `url(${plEntity.movie.thumbnail})`}}>
                                            <span className="fa-stack fa-3x">
                                                <i className="fas fa-circle fa-stack-2x"></i>
                                                <i id="icon" className="fas fa-robot fa-stack-1x fa-inverse"></i>
                                            </span>
                                        </div>
                                    )}                           

                            </div>
                        <br />
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-sm-9 col-12">
                <h2 id="tabelLabel">My Playlists</h2>
                <p>Check out your very own awesome collection of playlists!</p>
                {authenticated === true ? returnMyPlayLists() : returnRegisterBox()}
                {authenticated === true && myPlaylists ? renderPlaylistsTable(myPlaylists) : ""}
            </div>
            <div className="col-sm-3 col-12">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>
            <Toaster />
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} className="modalTitle">Create Playlist</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="playlistName">
                                Playlist Name
                            </Label>
                            <Input placeholder="My favourite playlist" id="playlistName" valid />
                            <FormFeedback>
                                You will not be able to see this
                            </FormFeedback>
                            <FormText>
                                50 characters maximum
                            </FormText>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit" onClick={()=>createPlayList()}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={modalEdit} toggle={toggleEdit}>
                <ModalHeader toggle={toggleEdit} className="modalTitle">Edit Playlist Name</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="playlistName">
                                Playlist Name
                            </Label>
                            <Input placeholder="My favourite playlist" value={playlistName} onChange={e => setPlaylistName(e.target.value)} id="playlistNameEdit" valid />
                            <FormFeedback>
                                You will not be able to see this
                            </FormFeedback>
                            <FormText>
                                50 characters maximum
                            </FormText>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleEdit}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit" onClick={() => updatePlayList()}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export { MyPlaylists_Page };