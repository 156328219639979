import React from 'react';
import { AdListing } from "../components/AdListing";

function SignIn_Page(props)
{
    async function signMeIn() {

        const uName = document.getElementById("loginUsername").value;
        const uPass = document.getElementById("loginPassword").value;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "Username": uName, "Password": uPass, "Rememberme": "true" })
        };

        const response = await fetch('/api/videocomment/signIn', requestOptions);
        document.location.href = "/authentication/login";

        return await response.json();
    }

    return (
        <div>
            <div className="row">
                <div className="col-sm-6 col-12">
                    <h1>Sign in</h1>   
                    <p>Cmooon, let me in already!</p>
                    <div className="row">
                        <div className="col-5">E-mail</div>
                        <div className="col-7"><input type="text" id="loginUsername" className="form-control prohibitusInput" /></div>
                    </div>
                    <div className="row">
                        <div className="col-5">Password</div>
                        <div className="col-7"><input type="password" id="loginPassword" className="form-control prohibitusInput" /></div>
                    </div>
                    <div className="row">                        
                        <div className="col-12"><button className="w-100 btn btn-lg btn-primary floatRight" onClick={() => signMeIn()}>Sign in</button>
                            <a href="forgotPassword">Gosh, I forgot my password!</a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 col-12">
                    <h2>Membership Perks</h2>
                    Why join you ask!
                    <ul>
                        <li>Because...it's cool...obviously!</li>
                        <li>Manage your own playlists</li>
                        <li>Rate our videos</li>
                        <li>Have your own favebox with favourite videos</li>
                        <li>Comment videos together with other peeps</li>
                        <li>Check out your very own toplists</li>
                        <li>Premium Content</li>
                        <li>Oh so much more to come!</li>
                        <li><a href="signup">Why wait? Sign me up dudes!</a></li>
                    </ul>
                </div>
                <div className="col-sm-3 col-12">
                    <h2>Our Partners</h2>
                    <AdListing url={"/video/"} />                                  
                </div>
            </div>
        </div>
    );   
}

export { SignIn_Page }