import React, { useEffect, useState } from 'react';
import FavouriteService from "../services/Favourite.service"
import authService from './../components/api-authorization/AuthorizeService';
import toast, { Toaster } from 'react-hot-toast';
import { AdListing } from "../components/AdListing";

function MyFavourites_Page(props) {

    const [authenticated, setAuthenticated] = useState([false]);
    const [myFavourites, setmyFavourites] = useState([]);
    const [loadingMovies, setLoadingMovies] = useState(true);

    useEffect(() => {
        populateState();
        loadMyFavourites();
    }, []);

    async function loadMyFavourites() {
        const token = await authService.getAccessToken();
        if (token !== null) {
            const data = await FavouriteService.getByUser(token);
            console.log(data)
            setmyFavourites(data);
            setLoadingMovies(false);
        }
    }

    async function populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        if (user) {            
            setAuthenticated(isAuthenticated);
        }
    }

    function returnRegisterBox() {
        return <div><h4>As a member you can add your very own favourite videos.</h4>
        <h4>Membership Perks</h4>
        Why join you ask!
            < ul >
            <li>Because...it's cool...obviously!</li>
            <li>Manage your own playlists</li>
            <li>Rate our videos</li>
            <li>Have your own favebox with favourite videos</li>
            <li>Comment videos together with other peeps</li>
            <li>Check out your very own toplists</li>
            <li>Premium Content</li>
            <li>Oh so much more to come!</li>
            </ul><h4><a href="/authentication/register">Sign up here to join our merry gang!</a></h4></div>
    }

    async function favourite(videoId) {
        const token = await authService.getAccessToken();
        await FavouriteService.favourite(videoId, token);
        loadMyFavourites();
        toast("favourite removed!")
    }

    function renderMoviesTable(movies, authenticated) {
        return (
            <div className="row">
                {movies.map(fave =>
                    <div className="col-sm-2 col-6" key={"movieHolder_" + fave.movie.wmcExternalId}>
                        <div className="divMovieListing" key={"movieCard_" + fave.movie.wmcExternalId}>
                            <a href="/video/" className="cursor">
                                <img title={fave.movie.title} alt={fave.movie.title} src={fave.movie.thumbnail} id={"thumb_" + fave.movie.wmcExternalId} className="imgThumb" />
                            </a>
                            <div className="divMovieListingInfo">
                                {authenticated === true ? <div className="row">
                                    <div className="col-9"><a href={"/video/" + fave.movie.wmcExternalId} className="cursor">{fave.movie.title}</a></div>
                                    <div className="col-3 button"><button className="btn-primary" onClick={() => favourite(fave.movie.id)}>X</button></div>
                                </div> : <div className="row">
                                        <div className="col-12"><a href={"/video/" + fave.movie.wmcExternalId} className="cursor">{fave.movie.title}</a></div>
                                </div>}
                            </div>
                        </div>

                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-sm-9 col-12">
                <h2 id="tabelLabel">My Favebox</h2>
                <p>Check out your very own box of favourite videos!</p>
                {authenticated === true ? "" : returnRegisterBox()}
                {loadingMovies ? "" : myFavourites.length === 0 ? <strong>No videos added...yet ;)</strong> : renderMoviesTable(myFavourites, authenticated)}
            </div>
            <div className="col-sm-3 col-12">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>
            <Toaster />            
        </div>
    );
}

export { MyFavourites_Page };