import React from 'react';
import { AdListing } from "../components/AdListing";

function Dashboard_Page(props) {
    return (
        <div className="row">
            <div className="col-sm-9 col-12">
                <h2>Here is your little corner of Prohibitus</h2>
                <div className="row">
                    <div className="col-9">
                        This is your little corner of the Prohibitus world. Your very own commandcenter if you will. ;)<br /><br />

                        <h4>Here are some fun things you can do</h4>
                        <ul>
                            <li>Manage your own <a href="/Myplaylists" className="fw-bolder">playlists</a></li>
                            <li>Rate our videos</li>
                            <li>Have your own <a href="/MyFavebox" className="fw-bolder">favebox</a> with favourite videos</li>
                            <li>Comment videos together with other awesome members</li>
                            <li>Check out your very own <a href="/MyToplists" className="fw-bolder">toplists</a></li>
                        </ul>
                        <br />
                        We are constantly working on new and fun things. If you have ideas and suggestions, don't hesitate to fire them away to us at hello[at]prohibitus.com
                    </div>
                    <div className="col-3 pe-5">
                        <h4><a href="/Identity/Account/Manage" class="floatRight">Manage My Profile</a></h4><br/>
                        <h4><a href="/Myplaylists" class="floatRight">My Playlists</a></h4><br />
                        <h4><a href="/MyFavebox" class="floatRight">My Favebox</a></h4><br />
                        <h4><a href="/MyToplists" class="floatRight">My Toplists</a></h4><br />
                    </div>
                </div>
            </div>
            <div className="col-sm-3 col-12">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>
        </div>
    );
}

export { Dashboard_Page };