import React from 'react';

function TermsAndConditions_Page (props) {

    return (
        <div className="row">
            <div className="col-12">
                <h2>Terms and Conditions</h2>
                <h3>ACCEPTANCE</h3>
                <p>By using and/or visiting the <a href="https://www.prohibitus.com/">https://www.prohibitus.com/</a> website (collectively, including, but not limited to, all Content and Uploaded Files available through the website), you agree to the Terms and Conditions contained in this document and the Terms and Conditions of the Privacy Policy of Prohibitus referred to in this document and all future amendments and modifications (collectively referred to as the Agreement).</p>
                <p>By entering you agree to be bound by these Terms and Conditions.</p>
                <p>The Terms and Conditions of this Agreement are subject to modification by Prohibitus at any time in its sole discretion and you agree to be bound by all modifications, changes and/or revisions.</p>
                <p>The Terms and Conditions contained in this document apply to all users of Prohibitus, whether they are a visitor or a member and you are only authorized to use Prohibitus if you agree to comply with all applicable laws and to be legally bound by the Terms and Conditions of this Agreement.</p>
                <h3>DESCRIPTION OF SERVICES</h3>
                <p>The Website allows registered and unregistered users to view visual representations of adult content, including sexually explicit images.</p>
                <p>Prohibitus allows its users to view the Content and the Website subject to the terms and conditions of this Agreement.</p>
                <p>Prohibitus's website may also contain certain links to third-party websites that are not owned or controlled by Prohibitus.</p>
                <p>Prohibitus assumes no responsibility for the content, privacy policies, practices of any third party sites.</p>
                <p>Prohibitus cannot censor or modify the content of third-party sites.</p>
                <p>You acknowledge that Prohibitus will not be subject to any and all liability arising from your use of any third-party site.</p>
                <p>Prohibitus is for your personal use and should not be used for any commercial endeavor except those specifically endorsed or approved by Prohibitus.</p>
                <p>Any illegal and/or unauthorized use of The Website is prohibited, including, but not limited to, the collection of usernames and email addresses for sending unsolicited emails or the unauthorized inclusion of The Website website using frames or unauthorized linking to The Website website.</p>
                <h3>ACCESS</h3>
                <p>To use this website, you affirm that you are at least eighteen (18) years old and/or the age of majority in the jurisdiction in which you reside and from which you are accessing the site or the age of majority is over eighteen (18) years of age.</p>
                <p>If you are under 18 and/or below the age of majority in the jurisdiction in which you reside and from which you are accessing the Website, you are not permitted to use the Site.</p>
                <h3>USER CONDUCT</h3>
                <p>You agree not to use Prohibitus in such a way as to expose Prohibitus to civil or criminal liability.</p>
                <p>You agree that Prohibitus has the right to determine, in its sole discretion, what action should be taken in the event of a discovered or reported violation of the Terms and Conditions contained herein.</p>
                <h3>INTELLECTUAL PROPERTY</h3>
                <p>Content on The Website, including but not limited to text, software, scripts, graphics, music, videos, photos, sounds, features interactives and the registered trademarks, service marks and logos contained therein belong to and/or are authorized by The Website, subject to copyright and other intellectual property rights under the Copyright law of Spain.</p>
                <p>The Content on the Website is provided to you AS IS for your information and personal use only and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed or otherwise exploited for any other purpose, without the prior authorization and written consent of the respective owners.</p>
                <p>Prohibitus reserves all rights not expressly granted in and to the Website and the Content.</p>
                <p>You agree not to engage in any use, copying or distribution of any Content other than as expressly permitted herein, including any use, copying and/or distribution of any third party submissions obtained on the website for commercial purposes.</p>
                <p>If you download or print a copy of the Content for personal use, you must retain all copyright and other proprietary notices contained herein.</p>
                <p>You agree not to disable, circumvent or interfere with the security-related features of The Website or features that prevent or limit the use or copying of any Content or limit the use of the The Website website or its Content.</p>
                <h3>ACCOUNT TERMINATION POLICY</h3>
                <p>A User's access to the Website will be terminated if, under appropriate conditions, the User is found to repeatedly violate these Terms and Conditions.</p>
                <p>The website reserves the right to decide whether the User&rsquo;s online behavior is appropriate and complies with these Terms and Conditions with respect to infringements other than copyright infringement.</p>
                <p>The Website may terminate a User's access for violation of these Terms and Conditions at any time, without notice and in its sole discretion.</p>
                <h3>COSTS</h3>
                <p>You acknowledge that Prohibitus reserves the right to charge for Prohibitus services and to modify its rates from time to time at its discretion.</p>
                <p>In addition, in the event that Prohibitus terminates your rights to use the website due to a violation of this Agreement, you will not be entitled to a refund of any unused portion of the subscription fees.</p>
                <h3>GUARANTEES</h3>
                <p>You represent and warrant that all information you have provided to the Prohibitus website is accurate and current, and you have all the rights, powers and authority necessary to enter into this Agreement and to perform the acts required hereafter.</p>
                <p>As a condition of using The Website, you must accept the terms of The Website privacy policy and its modifications.</p>
                <p>You acknowledge and agree that the technical processing and transmission of the Website, involves transmissions over various networks and changes to conform and adapt to technical requirements of connecting networks or devices.</p>
                <p>You also acknowledge and agree that other data collected and maintained by Prohibitus with respect to its users may be disclosed in accordance with Prohibitus's privacy policy.</p>
                <h3>DISCLAIMER OF WARRANTY</h3>
                <p>You agree that your use of the The Website is at your own risk.</p>
                <p>To the fullest extent permitted by law, The Website, its officers, directors, employees and agents disclaim all warranties, express or implied, in connection with the Website and your use.</p>
                <p>The Website does not warrant or make any representations as to the accuracy or completeness of the content of this site or the content of any sites linked to this site and assumes no responsibility for:</p>
                <ul>
                    <li>Any error, mistake or inaccuracy of content.</li>
                    <li>Any personal injury or property damage of any kind arising from your access to and use of our website.</li>
                    <li>Any unauthorized access to or use of our secure servers and/or any personal and/or financial information stored therein.</li>
                    <li>Any interruption or cessation of transmission to or from our website.</li>
                    <li>Any bugs, viruses, Trojan horses or the like which may be transmitted to or through our website by a third party, and/or</li>
                    <li>Any errors or omissions in any content or any loss or damage of any kind resulting from the use of any content posted, emailed, transmitted or made available on the Prohibitus website.</li>
                </ul>
                <p>Prohibitus does not guarantee, endorse, guarantee or assume responsibility for any product or service advertised or offered by a third party via the Prohibitus website or on a hyperlinked website or presented in a banner or other advertisement, and Prohibitus will not be a party -taking or in any way responsible for monitoring any transactions between you and third party providers of products or services.</p>
                <p>As with the purchase of any product or service through any medium or in any environment, you should use your best judgment and exercise caution where appropriate.</p>
                <p>The foregoing limitation of liability applies to the fullest extent permitted by law in the applicable jurisdiction.</p>
                <h3>LIMITATION OF LIABILITY</h3>
                <p>Under no circumstances will Prohibitus, its officers, directors, employees or agents be liable to you for any direct, indirect, incidental, special, punitive or consequential damages resulting from:</p>
                <ul>
                    <li>Any error, mistake or inaccuracy of content.</li>
                    <li>Any personal injury or property damage of any kind arising from your access to and use of our website.</li>
                    <li>Any unauthorized access to or use of our secure servers and/or any personal and/or financial information stored therein.</li>
                    <li>Any interruption or cessation of transmission to or from our website.</li>
                    <li>Any bugs, viruses, Trojan horses or the like which may be transmitted to or through our website by a third party, and/or</li>
                    <li>Any errors or omissions in any content or any loss or damage of any kind resulting from the use of any content posted, emailed, transmitted or made available on the Prohibitus website, whether in contract or tort , whether they relate to warranty or are based on any other legal theory, and whether or not the company is aware of the possibility of such damages.</li>
                </ul>
                <p>The foregoing limitation of liability applies to the fullest extent permitted by law in the applicable jurisdiction.</p>
                <h3>INDEMNITY</h3>
                <p>You agree to defend, indemnify and hold harmless Prohibitus, its parent company, officers, directors, employees and agents, against any claim, damages, obligations, losses, liabilities, costs or debts, as well as expenses ( including, but not limited to) attorney fees from:</p>
                <ul>
                    <li>Your use of and access to the The Website website.</li>
                    <li>Your violation of any term of these Terms and Conditions.</li>
                    <li>Your violation of any third-party right, including without limitation any copyright, property, or privacy right.</li>
                </ul>
                <p>Any claim that you have caused damage to a third party. This defense and indemnification obligation will survive these Terms and Conditions and your use of The Website's website.</p>
                <p>You affirm that you are over eighteen (18) years of age and/or the age of majority in the jurisdiction where you reside and are fully capable and competent to enter into the terms, conditions, obligations, affirmations, representations and warranties set forth in these Terms and Conditions and to comply with these Terms and Conditions contained herein.</p>
                <h3>JURISDICTION</h3>
                <p>This Agreement shall be governed by and construed in accordance with the laws of the country of Spain, without regard to conflicts of law principles.</p>
                <p>Sole and exclusive jurisdiction and venue in any action or proceeding arising out of or relating to this Agreement shall be in a court of competent jurisdiction located in the country of Spain.</p>
                <p>You hereby submit to the jurisdiction and venue of such courts.</p>
                <p>You accept all legal action in any legal proceeding.</p>
            </div>
        </div>
    );   
}

export { TermsAndConditions_Page }