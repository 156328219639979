class VideoDataService
{
    // Retrieve all videos
    async getAll() {
        const response = await fetch('/api/video/');
        return await response.json();
    }

    // Retrieve video based on id
    async get(id) {
        return await fetch("/api/video/getmovie?id=" + id).then((data) => { return data.json() }).catch((error) => console.log(error))
    }

    // Retrieve all videos for an actor based on actor id
    async getByActorId(id) {
        const response = await fetch("/api/video/getbyactor?actorId=" + id);
        return await response.json();
    }

    // Retrieve videos based on filtering criteria
    async getMovies(listingType, listingFilter, listingAmount, listingPage = 0, token) {
        let movieListingURL = "";
        switch (listingType) {
            case 'mostpopular':
                movieListingURL = "/api/video?amount=" + listingAmount;
                break;
            case 'actorlisting':
                movieListingURL = "/api/video/getbyactor?actorId=" + listingFilter + "&amount=" + listingAmount;
                break;
            case 'categorylisting':
                movieListingURL = "/api/video/getbycategory?categoryId=" + listingFilter + "&amount=" + listingAmount + "&page=" + listingPage;
                break;
            case 'relatedvideos':
                movieListingURL = "/api/video/getrelatedvideos?videoId=" + listingFilter + "&amount=" + listingAmount;
                break;
            case 'mymostviewed':
                movieListingURL = "/api/video/getmymostviewed?amount=" + listingAmount;
                break;
            case 'myhighestrating':
                movieListingURL = "/api/video/getmyhighestrating?amount=" + listingAmount;
                break;
            case 'mostviewed':
                movieListingURL = "/api/video/getmostviewed?amount=" + listingAmount + "&page=" + listingPage;
                break;
            case 'mostcommented':
                movieListingURL = "/api/video/getmostcommented?amount=" + listingAmount + "&page=" + listingPage;
                break;
            case 'highestrating':
                movieListingURL = "/api/video/gethighestrating?amount=" + listingAmount + "&page=" + listingPage;
                break;
            case 'mostrated':
                movieListingURL = "/api/video/getmostrated?amount=" + listingAmount + "&page=" + listingPage;
                break;
            case 'playlist':
                movieListingURL = "/api/video/getbyplaylist?playlistId=" + listingFilter + "&amount=" + listingAmount;
                break
            default:
                movieListingURL = "/api/video?amount=" + listingAmount;
                break;
        }
            
        if (token) {
            const response = await fetch(movieListingURL, { headers: { 'Authorization': `Bearer ${token}` } });
            return await response.json();
        } else {
            const response = await fetch(movieListingURL);
            return await response.json();
        }
    }

    async getByCategoryId(id) {
        const response = await fetch("/api/video/getbycategory?categoryId" + id);
        return await response.json();
    }

    async getRelatedByVideoId(id) {
        const response = await fetch("/api/video/getbyvideo?videoId=" + id);
        return await response.json();
    }

    async getByTopListId(id) {
        const response = await fetch("/api/video/getbytoplist?toplistId=" + id);
        return await response.json();
    }

    async getByPlaylistId(id) {
        const response = await fetch("/api/video/getbyplaylist?playlistId=" + id);
        return await response.json();
    }

    async rateVideo(rating, id) {
        const response = await fetch("/api/video/ratevideo?rating=" + rating + "&videoId=" + id);
        return await response.json();
    }

    async logVideoView(id, token) {
        if (token) {
            const response = await fetch("/api/video/loguservideoview?videoId=" + id, { headers: { 'Authorization': `Bearer ${token}` } });
            return await response.json();
        } else {
            const response = await fetch("/api/video/logvideoview?videoId=" + id);
            return await response.json();
        }
    }
}
export default new VideoDataService();