import React, { useEffect, useState } from 'react';
import ActorService from "../services/Actor.service";
import PlaylistService from "../services/Playlist.service";
import CategoryService from "../services/Category.service";

function SiteMap_Page(props) {

    const [categories, setCategories] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [playlists, setPlaylists] = useState([]);
    const [loadingPlaylists, setLoadingPlaylists] = useState(true);
    const [actors, setActors] = useState([]);
    const [loadingActors, setLoadingActors] = useState(true);
    
    useEffect(() => {
        loadCategories();
        loadPlaylists();
        loadActors();
    }, []);

    async function loadActors() {
        var tmpActor = await ActorService.getAll();
        console.log(tmpActor)
        setActors(tmpActor);
        setLoadingActors(false);
    }

    async function loadPlaylists() {
        const data = await PlaylistService.getAll();
        setPlaylists(data);
        setLoadingPlaylists(false);
    }

    async function loadCategories() {
        const data = await CategoryService.getAll();
        setCategories(data);
        setLoadingCategories(false);
    }

    function renderActorsListing(theseActors) {
        return (
            <ul>
                {theseActors.map(actor =>
                    <li key={"movieHolder_" + actor.wmcExternalId}>
                        <a href={"/actor/" + actor.firstName + "%20" + actor.lastName} key={"movieLink_" + actor.wmcExternalId}>{actor.firstName + " " + actor.lastName}</a>
                    </li>
                )}
            </ul>
        );
    }

    function renderCategories(theseCategories, orientation) {
        theseCategories = theseCategories.filter(x => x.categoryPicture !== null);
        return (theseCategories.map((chosenCategory, index) => <li key={"navBarCategoryLI_" + chosenCategory.wmcExternalId} onClick={() => document.location.href = "/category/" + chosenCategory.wmcExternalId + "/" + chosenCategory.name}><a href={"/category/" + chosenCategory.wmcExternalId + "/" + chosenCategory.name}>{chosenCategory.name}</a></li>))
    }

    function renderPlaylists(thesePlaylists) {
        return (
            <ul>
                {thesePlaylists.filter(x => x.playlistMovies.length > 4).map(playlist =>
                    <li key={"playlistHolder_" + playlist.id}><a href={"/playlist/" + playlist.id}>{playlist.name}</a></li>
                )}
            </ul>
        );
    }

    return (
        <div>
            <h2>Sitemap</h2>
            <p>Here is an overview of what you can find on our most eminent site!</p>
            <div className="row">
                <div className="col-3">
                    <ul>
                        <li>Videos</li>
                        <ul>
                            {loadingCategories ? "" : renderCategories(categories, 3)}
                        </ul>
                    </ul>
                </div>
                <div className="col-3">
                    <ul>
                        <li>Toplists</li>
                        <ul>
                            <li><a href="/toplists/mostviewed">Most Viewed</a></li>
                            <li><a href="/toplists/highestrating">Highest Rating</a></li>
                            <li><a href="/toplists/mostrated">Most Rated</a></li>
                            <li><a href="/toplists/mostcommented">Most Commented</a></li>
                        </ul>
                    </ul>
                </div>
                <div className="col-3">
                    <ul>
                        <li>Playlists</li>
                        {loadingPlaylists ? <p><em>Loading Playlists...</em></p> : renderPlaylists(playlists)}
                    </ul>
                </div>
                <div className="col-3">
                    <ul>
                        <li>Stars</li>
                        {loadingActors ? <p><em>Loading our list of fabulous stars...</em></p> : renderActorsListing(actors)}
                    </ul>
                </div>
            </div>            
        </div>
    );   
}

export { SiteMap_Page }