import React, { useEffect, useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { LoginMenu } from './../api-authorization/LoginMenu';
import { Search } from './Search';
import Prohibituslogo from './../../assets/media/prohibitus_logo.png';
import CategoryService from "../../services/Category.service";
import { StarFill, CardList, HandThumbsUpFill, Fire, Box2Heart } from 'react-bootstrap-icons';

function Header (props) {

    const [collapsed, setCollapsed] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);

    useEffect(() => {
        loadCategories();
    }, []);

    async function loadCategories() {
        const theseCategories = await CategoryService.getAll();
        setCategories(theseCategories);
        setLoadingCategories(false);
    }

    function renderItems(chosenCategory, index) {
        return <li key={"navBarCategoryLI_" + chosenCategory.wmcExternalId}><a className="dropdown-item" href={"/category/" + chosenCategory.wmcExternalId + "/" + chosenCategory.name}>{chosenCategory.name}</a></li>
    }

    function renderCategories(theseCategories) {
        theseCategories = theseCategories.filter(x => x.categoryPicture !== null);
        var columnHeight = 9;
        var length = theseCategories.length;
        var rows = [];
        for (var i = 0; i < length; i = i + columnHeight) {
            rows.push(<ul key={"navBarCategoryUL_" + i}>
                {theseCategories.slice(i, i + columnHeight).map((chosenCategory, index) => renderItems(chosenCategory, index))}
            </ul>)
        }
        return (
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                {rows}
            </div>
        );
    }

    return (
        <header className="sticky-top">
           <div className="headerTop"></div>    
           <Navbar className="navbar-expand-lg navbar-toggleable-lg ng-white border-bottom box-shadow mb-3" light>
                <NavbarBrand href="/">
                    <img src={Prohibituslogo} width="150" alt="Prohibitus - For and by lovers of adult entertainment" title="Prohibitus - For and by lovers of adult entertainment" className="d-inline-block align-middle mr-2 headerLogo" />
                </NavbarBrand>
                <div className="row">
                    <div className="col-6">
                        <Search />
                    </div>
                    <div className="col-6">
                        <ul className="navbar-nav flex-grow navLogin">
                            <LoginMenu extraClass="mobileHide" />
                        </ul>
                    </div>
                    <div className="col-12">
                        <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2 custom-toggler" />
                        <Collapse className="d-sm-inline-flex flex-sm-row" isOpen={!collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="videos" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <Fire className="icon" /> VIDEOS
                                    </a>
                                    {loadingCategories ? "" : renderCategories(categories,0)}
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="toplists/mostviewed" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <HandThumbsUpFill className="icon" /> TOPLISTS
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <ul>
                                            <li><a className="dropdown-item" href="/toplists/mostviewed">Most Viewed</a></li>
                                            <li><a className="dropdown-item" href="/toplists/highestrating">Highest Rating</a></li>
                                            <li><a className="dropdown-item" href="/toplists/mostrated">Most Rated</a></li>
                                            <li><a className="dropdown-item" href="/toplists/mostcommented">Most Commented</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/playlists"><CardList className="icon" /> PLAYLISTS</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/actors"><StarFill className="icon" /> STARS</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/myplaylists"><CardList className="iconMember" /> MY PLAYLISTS</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/myfavourites"><Box2Heart className="iconMember" /> MY FAVEBOX</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/mytoplists"><HandThumbsUpFill className="iconMember" /> MY TOPLISTS</a>
                                </li>                                
                                <LoginMenu extraClass="desktopHide" />                                
                            </ul>
                        </Collapse>
                    </div>
                </div>         
        </Navbar>
      </header>
    );
}

export { Header }