import React from 'react';
import { useParams } from "react-router-dom";
import { AdListing } from "../components/AdListing";
import toast, { Toaster } from 'react-hot-toast';

function ResetPassword_Page (props)
{
    const params = useParams();

    async function resetPassword() {

        const email = document.getElementById("email").value;
        const password = document.getElementById("password").value;
        const passwordConfirm = document.getElementById("passwordconfirm").value;

        if (!email || !password | !passwordConfirm) {
            toast("You need to fill in all fields");
            return false;
        }

        if (password !== passwordConfirm) {
            toast("Passwords do not match!");
            return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "Email": email, "Password": password, "Code": params.code })
        }
        const response = await fetch('/api/videocomment/resetpassword', requestOptions);

        toast("Your password has been reset")

        document.getElementById("email").value = "";
        document.getElementById("password").value = "";
        document.getElementById("passwordconfirm").value = "";

        return await response.json();
    }

    return (
        <div className="row">
            <div className="col-sm-6 col-12">
                <Toaster/>
                <h2>Let's fix that password!</h2>
                <p>Change your password in the form below!</p>
                <div className="row">
                    <div className="col-3">E-mail</div>
                    <div className="col-9"><input type="email" id="email" className="form-control prohibitusInput" /></div>
                </div>
                <div className="row">
                    <div className="col-3">New Password</div>
                    <div className="col-9"><input type="password" id="password" className="form-control prohibitusInput" /></div>
                </div>
                <div className="row">
                    <div className="col-3">Confirm Your New Password</div>
                    <div className="col-9"><input type="password" id="passwordconfirm" className="form-control prohibitusInput" /></div>
                </div>
                <div className="row">
                    <div className="col-12"><button className="w-100 btn btn-lg btn-primary floatRight" onClick={() => resetPassword()}>Remind me</button></div>
                </div>
            </div>
            <div className="col-3">
                <h2>Reasons to forgive yourself</h2>
                <ul>
                    <li>You made up a crazy complicated password, which was a bit too good to remember even though they should be complicated</li>
                    <li>Your goldfish may have eaten the password</li>
                    <li>You may not even have a goldfish</li>
                </ul>
            </div>
            <div className="col-sm-3 col-12">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>            
        </div>
    );
}

export { ResetPassword_Page };