import React, { useEffect, useState } from 'react';
import {
    Popover,
    PopoverBody,
    PopoverHeader,
    Spinner
} from 'reactstrap';
import { useParams } from "react-router-dom";
import VideoService from "../services/Video.service";
import PlaylistService from "../services/Playlist.service"
import FavouriteService from "../services/Favourite.service"
import CommentDataService from "../services/Comment.service"
import toast, { Toaster } from 'react-hot-toast';
import authService from './../components/api-authorization/AuthorizeService';
import { ClockFill, HandThumbsUpFill, EyeFill, CardList, Box2Heart, FlagFill, ReplyFill } from 'react-bootstrap-icons';

function VideoView (props)
{
    const params = useParams();
    const [authenticated, setAuthenticated] = useState([false]);
    const [currentParent, setCurrentParent] = useState("00000000-0000-0000-0000-000000000000");
    const [avgRating, setAvgRating] = useState([]);
    const [playlists, setPlaylists] = useState([]);
    const [comments, setComments] = useState([]);
    const [loadingComments, setLoadingComments] = useState([]);
    const [loadingPlaylists, setLoadingPlaylists] = useState([]);
    const [movie, setMovie] = useState([]);
    const [loadingMovieContents, setLoadingMovieContents] = useState(true);
    const [popoverOpen, setPopoverOpen] = React.useState(false);

    useEffect(() => {
        setLoadingMovieContents(true);
        populateState();
        var curVid = params.videoId ? params.videoId : props.videoId;
        loadMovieInfo(curVid);
        loadComments(curVid);
        loadMyPlayLists();
    }, [props.videoId]);

    function notLoggedInNotification() {
        toast("Ooops, no can do! You have to be logged in for this!");
    }

    async function loadComments(videoId) {
        const data = await CommentDataService.getCommentsByVideo(videoId);
        setComments(data);
        setLoadingComments(false);
    }

    function renderComments(commentData) {
        return <div>
                    {renderSubComments("00000000-0000-0000-0000-000000000000", commentData)}
              </div>
    }

    async function report(commentId) {
        const token = await authService.getAccessToken();
        if (!token) {
            notLoggedInNotification();
            return false;
        }
        var feedback = prompt("Please write your reason for reporting this comment, and we will look into it as soon as we can!");
        if (feedback) {
            await CommentDataService.reportComment(commentId, feedback, token);
            toast("Thank you for your input! Together we strive to make Prohibitus great for everybody!");
        } else {
            toast("You need to provide feedback for your report. Please try again!");
        }
    }

    async function respond(commentId) {
        const token = await authService.getAccessToken();
        if (!token) {
            notLoggedInNotification();
            return false;
        }
        setCurrentParent(commentId);
        document.getElementById("txtComment").focus();
    }

    async function sendComment(videoId) {
        const token = await authService.getAccessToken();
        if (!token) {
            notLoggedInNotification();
            return false;
        }

        var parent = currentParent;
        if (parent === "" | parent === undefined | parent===null) {
            parent = "00000000-0000-0000-0000-000000000000";
        }

        var comment = document.getElementById("txtComment").value;
        await CommentDataService.addCommentToVideo(videoId, comment, parent, token);
        document.getElementById("txtComment").value = "";
        loadComments(videoId);
        toast("Thanks for your thoughts on this vid!");
    }

    function formatDate(inDate) {
        inDate = new Date(inDate);
        return new Intl.DateTimeFormat('da-DK', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(inDate);
    }

    function renderSubComments(commentId, commentData) {
        return commentData.filter(x => x.parentCommentId === commentId).map(commentItem =>
            <div key={"commentEntry" + commentItem.id}>
                <span className="commentSender"><a href={"/member/" + commentItem.userId + "/" + commentItem.userName}>{commentItem.userName}</a></span> -
                <span className="commentDate">{formatDate(commentItem.createdDate)}</span> -
                <FlagFill className="icon" onClick={() => report(commentItem.id)} alt="Report this comment" title="Report this comment" /> -
                <ReplyFill className="icon" onClick={() => respond(commentItem.id)} alt="Reply to this comment" title="Reply to this comment" />
                <br />
                <span className="commentBody">{commentItem.body}</span>
                <div className="commentChildren">{renderSubComments(commentItem.id, commentData)}</div>
            </div>);
    }

    async function populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        if (user) {
            setAuthenticated(isAuthenticated);
        }
    }

    async function loadMovieInfo(movieId) {
        const data = await VideoService.get(movieId);
        setMovie(data);
        let avg =
            data.movieRatings.reduce((sum, curr) => sum + Number(curr.rating), 0) /
            data.movieRatings.length;
        if (isNaN(avg) || avg===null || avg===undefined) {
            avg = "";
        }
        setAvgRating(avg);
        setLoadingMovieContents(false);
        logVideoView(movieId);
    }

    async function loadMyPlayLists() {
        const token = await authService.getAccessToken();
        if (token!=="" && token!==undefined & token!==null) {
            const data = await PlaylistService.getByUser(token);
            setPlaylists(data);
            setLoadingPlaylists(false);
        }
    }

    function renderMyPlaylists(playlistData, videoId) {
        return <ul>
                {playlistData.map(playlistItem => <li key={"playlistEntry" + playlistItem.id} className="cursor" title="Add the video to this playlist" alt="Add the video to this playlist" onClick={() => addVideoToPlaylist(playlistItem.id, videoId)}>{playlistItem.name}</li>)}
               </ul>
    }

    async function rateVideo(rating, movieId) {
        const token = await authService.getAccessToken();
        if (!token) {
            notLoggedInNotification();
            return false;
        }
        await VideoService.rateVideo(rating, movieId, token);
        toast("Thanks for your rating!");
    }

    async function logVideoView(movieId) {
        const token = await authService.getAccessToken();
        await VideoService.logVideoView(movieId, token);
    }

    async function addVideoToPlaylist(playlistId, videoId) {
        const token = await authService.getAccessToken();
        if (!token) {
            notLoggedInNotification();
            return false;
        }
        await PlaylistService.addVideo(playlistId, videoId, token);
        toast("Video added to your playlist!");
    }

    async function togglePopup() {
        const token = await authService.getAccessToken();
        if (!token) {
            notLoggedInNotification();
            return false;
        }

        setPopoverOpen(!popoverOpen);
    }

    async function addVideoToFavourites(videoId) {
        const token = await authService.getAccessToken();
        if (!token) {
            notLoggedInNotification();
            return false;
        }

        await FavouriteService.favourite(videoId, token);
        toast("Video added to your favourites!");
    }

    function renderMovieView(activeMovie) {
        return (<div>
                    <h2>{activeMovie.title}</h2>
                    <video id="prohibitusPlayer" src={activeMovie.videoURL} width="100%" height="auto" controls poster="images/FFFFFF-0.png"></video><br/>
                    <div className="divMovieInfoViewArea">
                        <div className="divMovieInfoLength">
                            <ClockFill className="icon" /> {activeMovie.length}
                        </div>
                        <div className="divMovieInfoRating" alt="Rate this video" title="Rate this video">
                            <HandThumbsUpFill className="icon" />
                            <button className="btn-primary" alt="Rate this video with the score 1" title="Rate this video with the score 1" onClick={() => rateVideo(1, activeMovie.wmcExternalId)}>1</button>
                            <button className="btn-primary" alt="Rate this video with the score 2" title="Rate this video with the score 2" onClick={() => rateVideo(2, activeMovie.wmcExternalId)}>2</button>
                            <button className="btn-primary" alt="Rate this video with the score 3" title="Rate this video with the score 3" onClick={() => rateVideo(3, activeMovie.wmcExternalId)}>3</button>
                            <button className="btn-primary" alt="Rate this video with the score 4" title="Rate this video with the score 4" onClick={() => rateVideo(4, activeMovie.wmcExternalId)}>4</button>
                            <button className="btn-primary" alt="Rate this video with the score 5" title="Rate this video with the score 5" onClick={() => rateVideo(5, activeMovie.wmcExternalId)}>5</button>
                            &nbsp;&nbsp;{avgRating}
                        </div>
                        <div className="divMovieInfoViews" alt="Video views" title="Video views">
                            <EyeFill className="icon" alt="Video views" title="Video views" /> {activeMovie.movieStats.length}
                        </div> 
                        <div className="divMovieInfoFavourite" alt="Add to your box of favourites" title="Add to your box of favourites">
                            <Box2Heart className="icon" onClick={() => addVideoToFavourites(activeMovie.id)} /> Fave
                        </div> 
                        <div className="divMovieInfoPlaylist" alt="Add to one of your playlists" title="Add to one of your playlists">
                            <CardList className="icon" id="Popover1" /> Add to Playlist
                            <Popover
                                placement="top" isOpen={popoverOpen}
                                target="Popover1" toggle=
                                {() => { togglePopup() }}>
                                <PopoverHeader>Add to my Playlist</PopoverHeader>
                                <PopoverBody>{loadingPlaylists ? "" : renderMyPlaylists(playlists,activeMovie.id)}</PopoverBody>
                            </Popover>
                        </div>
                        <br />
                        <br />
                        <div className="divMovieInfoCategoriesActors">
                            Categories: {activeMovie.movieMovieCategories.map(categoryItem => <a href={"/category/" + categoryItem.movieCategory.wmcExternalId + "/" + categoryItem.movieCategory.name} key={"categorypill" + categoryItem.movieCategory.id} className="categoryPill">{categoryItem.movieCategory.name}</a>)}<br />
                            {activeMovie.movieActors.length > 0 ? <span>Actors:</span> : ""} {activeMovie.movieActors.map(actorEntity => <a href={"/actor/" + actorEntity.actor.firstName + "%20" + actorEntity.actor.lastName} key={"actorpill" + actorEntity.movieId} className="categoryPill">{actorEntity.actor.firstName + " " + actorEntity.actor.lastName}</a>)}
                </div>
                <br />
                <div className="divMovieCommenting">
                    {!authenticated ? <h5>Sign up or sign in to leave your comment on this vid!</h5> :
                        <div className="row">
                            <div className="col-10"><textarea placeholder="This vid gets me thinking about..." id="txtComment" className="txtComment form-control"></textarea></div>
                            <div className="col-2 rightAligned"><button onClick={() => sendComment(activeMovie.wmcExternalId)} className="btn-primary">Fire away!</button></div>
                        </div>}
                    <br/>
                    <h5>Comments</h5>
                    {loadingComments ? <span><Spinner className="sm" /> Loading comments from peeps in our most fabulous gang...</span> : comments.length === 0 ? "No comments...yet! Be the first to share your thoughts on this vid!" : renderComments(comments)}
                </div>
                    </div>
                </div>);
    }

    return (
        <div><Toaster />{loadingMovieContents ? <span><Spinner className="sm" /> Loading your video...</span> : renderMovieView(movie)}</div>
    );
}

export { VideoView };