import React, { useEffect, useState } from 'react';
import CategoryService from "../services/Category.service";
import { AdListing } from "../components/AdListing";

function Videos_Page()
{
    const [categories, setcategories] = useState([]);
    const [loadingcategories, setLoadingcategories] = useState(true);

    useEffect(() => {
        loadCategories();
    }, []);

    async function loadCategories() {
        const theseCategories = await CategoryService.getAll();
        setcategories(theseCategories);
        setLoadingcategories(false);
    }

    function renderCategoriesListing(theseCategories) {
        return (
            <div className='row'>
                {theseCategories.filter(x=>x.categoryPicture!==null).map(category =>
                    <div className="col-md-2 col-sm-2 col-xs-2" key={"movieHolder_" + category.wmcExternalId}>
                        <div className="divActorListing" key={"movieCard_" + category.wmcExternalId}>
                            <a className="categoryLink" href={"/category/" + category.wmcExternalId + "/" + category.name} key={"movieLink_" + category.wmcExternalId}>
                                <img src={category.categoryPicture} className="categoryProfilePicture" /><br />
                                {category.name}
                            </a>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return (
      <div>
        <div className='row'>
            <div className='col-sm-9 col-12'>
                <h2>Our Categories</h2>
                <p>Here is a listing of our groovy categories...and this list will only keep on growing, because we love this stuff and will keep on collecting!</p>
                    {loadingcategories ? <p><em>Loading our list of groovy categories...</em></p> : renderCategoriesListing(categories)}
            </div>
            <div className="col-sm-3 col-12">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>            
        </div>
      </div>
    );
}

export { Videos_Page };