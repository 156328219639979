import React from 'react';

function Records_Page (props) {

    return (
            <div>
                <div className="col-12">
                    <h2>Record Keeping Information</h2>

                    All models, actors, actresses and other persons that appear in any visual depiction of actual or simulated sexual conduct appearing or otherwise contained in at this website were over the age of eighteen (18) years at the time of the creation of such depictions.<br /><br />
                        Some of the aforementioned depictions appearing or otherwise contained in or at this site contain only visual depictions of actual sexually explicit conduct made before July 3, 1995, and, as such, are exempt from the requirements set forth in 18 U.S.C. &#167; 2257 and C.F.R. 75.<br /><br />
                        With regard to the remaining depictions of actual sexual conduct appearing or otherwise contained in or at this site, the records required pursuant to 18 U.S.C. &#167; 2257 and C.F.R. 75 are kept in the following locations by the corresponding Custodians of Records:<br /><br />
                    For inquiries about specific material contained herein and the corresponding Custodian of Records info pertaining to those items, please contact<br /><br />

                    Custodian of Records<br />
                    Prohibitus<br />
                    hello@prohibitus.com<br /><br />

                    The original records required pursuant to 18 U.S.C. section 2257 and 28 C.F.R. 75 for all materials contained in the website are kept by the following Custodian of Records:

                    <br /><br />

                    <table width="100%" border="0" cellspacing="5" cellpadding="5">
                        <tbody>
                            <tr>
                                <td>Powerhouse<br />
                                    B. Rinaldi, Custodian of Records<br />
                                    21418 Panthenia St.<br />
                                    Canoga Park, CA 91304</td>
                                <td>Bob's T Girl Studio<br />
                                    BTG, Custodian of Records<br />
                                    19360 Rinaldi St. Suite 429<br />
                                    Northridge, CA 91326</td>
                                <td>Bordellonoir Studio<br />
                                    C. Lodge, Custodian of Records<br />
                                    8235 Santa Monica Blvd., Suite 301A<br />
                                    W. Hollywood, CA 90069</td>
                            </tr>
                            <tr>
                                <td>Grey Rose Production<br />
                                    C. Meister, Custodian of Records<br />
                                    2545 A Street # 17<br />
                                    San Diego, CA 92102</td>
                                <td>Bacchus<br />
                                    Custodian of Records:<br />
                                    M. Chalfin, at the office production:<br />
                                    9718 GlenOaks Blvd. Unit B, Sun Valley CA 91352</td>
                                <td>Rain<br />
                                    Custodian of Records:<br />
                                    M. Weston, at the office production:<br />
                                    8735 Shirley Ave.<br />
                                    Northridge CA 91324</td>
                            </tr>
                            <tr>
                                <td>XP Video<br />
                                    Custodian of Records:<br />
                                    A Rottenburg, at the office of production:<br />
                                    1407 E. 5th Ave., Tampa, FL 33605</td>
                                <td>3 Vision<br />
                                    Custodian of Records:<br />
                                    A. Smith, at the office of production:<br />
                                    3166 E. Palmdale Blvd., Palmdale CA 93550</td>
                                <td>Old Pueblo<br />
                                    Custodian of Records:<br />
                                    B. Cohen, at the office of production:<br />
                                    520 Washington Blvd. Suite 932<br />
                                    Marina Del Rey, CA 90292</td>
                            </tr>
                            <tr>
                                <td>Feline Films<br />
                                    Custodian of Records:<br />
                                    B. Cohen, at the office of production:<br />
                                    9145 Owensmouth Ave., Chatsworth, CA 91311</td>
                                <td>Wild Life<br />
                                    Custodian of Records:<br />
                                    Bobby Rinaldi, at the office of production:<br />
                                    8501 Canoga Ave., Unit B,<br />
                                    Canoga Park, CA 91304</td>
                                <td>Nasty Boyz Studio<br />
                                    Custodian of Records:<br />
                                    D. Bonneville, at the office of production:<br />
                                    3885-J Cochran St. #328<br />
                                    Simi Valley, CA 93063</td>
                            </tr>
                            <tr>
                                <td>Daydream Productions<br />
                                    Custodian of Records:<br />
                                    D. Mech, at the office of production:<br />
                                    1851 Topopock St., Glendale, CA 91204</td>
                                <td>Lust World Entertainment<br />
                                    Custodian of Records:<br />
                                    D. Monroe, at the office of production:<br />
                                    415 N. El Molino Ave., Pasadena CA 91101</td>
                                <td>Dirty Diva Productions<br />
                                    Custodian of Records:<br />
                                    E. Robinson at the office of production.:<br />
                                    127 Hendy Creek RD, Unit B, Pine City NY, 14871</td>
                            </tr>
                            <tr>
                                <td>Starr Productions<br />
                                    Custodian of Records:<br />
                                    Elizabeth Starr, at the office of production:<br />
                                    6758 Franklin Place, Hollywood CA 90028</td>
                                <td>American Xcess<br />
                                    Custodian of Records:<br />
                                    H. Jose, at the office of production:<br />
                                    9537 Cozycroft Ave., Chatsworth, CA 91311</td>
                                <td>Black Mirror Studio<br />
                                    Custodian of Records:<br />
                                    J. Gallant at the office production:<br />
                                    676-A Ninth Ave. #329 NYC NY 10036</td>
                            </tr>
                            <tr>
                                <td>Notorious<br />
                                    Custodian of Records:<br />
                                    J. Lane, at the office of production:<br />
                                    9730 Variel Ave., Chatsworth CA 91311</td>
                                <td>Altered State<br />
                                    Custodian of Records:<br />
                                    Joann Carter-Davis, Altered State Prosuctions Inc.:<br />
                                    7334 Tpanga Cyn. Bl #218., Canoga Park CA, 91303</td>
                                <td>Just Fine Productions<br />
                                    Custodian of Records:<br />
                                    John Fantasy, at the office of production:<br />
                                    400 E. Old Plank Rd., Columbia, MO 65203</td>
                            </tr>
                            <tr>
                                <td>Bricate Entertainment<br />
                                    Custodian of Records:<br />
                                    Jon Ray, at the office of production:<br />
                                    13029 A Victory Blvd #334,<br />
                                    North Hollywod CA, 91606</td>
                                <td>Black City Productions<br />
                                    Custodian of Records:<br />
                                    K. Innis, at the office of production:<br />
                                    36-08 34th St., Studio #1, LIC NY 11106</td>
                                <td>OSK Productions<br />
                                    Custodian of Records:<br />
                                    M. Stever, at the office of production:<br />
                                    889 Exmouth St. Unit 9<br />
                                    Sarnia, Ontario, Canada</td>
                            </tr>
                            <tr>
                                <td>Acid Rain<br />
                                    Custodian of Records:<br />
                                    M. Weston, at the office of production:<br />
                                    8735 Shirley Ave., Northridge CA 91324</td>
                                <td>Horizon Entertainment<br />
                                    Custodian of Records:<br />
                                    R. Gibson, at Horizon Entertainment :<br />
                                    630 Quintana Road, Morro Bay, CA 93442</td>
                                <td>Web Coast International<br />
                                    Custodian of Records:<br />
                                    R.Glenn at the office production:<br />
                                    9245 Reseda Blvd. Suite 449<br />
                                    Northridge CA 91324</td>
                            </tr>
                            <tr>
                                <td>XXX Home Videos<br />
                                    Custodian of Records:<br />
                                    Ralph Sosa and JJ Digital Production<br />
                                    2401 N.W. 30th Avenue<br />
                                    Miami, FL 33142</td>
                                <td>Colossal Entertainment<br />
                                    Custodian of Records:<br />
                                    S. Baer, at the office of production:<br />
                                    5362 Cahuenga Blvd., North Hollywood, CA 91601</td>
                                <td>Cinderella<br />
                                    Custodian of Records:<br />
                                    S. Brickman, C.D.I., at the office of production:<br />
                                    8021 Remmet Ave., Canoga Park CA 91304</td>
                            </tr>
                            <tr>
                                <td>Brookland Bros.<br />
                                    Custodian of Records:<br />
                                    S. Brookland, at the office production:<br />
                                    Brookland Bros Ent, LLC.<br />
                                    317 SW Alder #200 Portland, OR 97204</td>
                                <td>High Def Home Entertainment<br />
                                    Custodian of Records:<br />
                                    T. Funk, at the office production:<br />
                                    3225 Mcleod Dr., Suite 100, Las Vegas, NV 89121</td>
                                <td>Joy Ride Entertainment<br />
                                    Custodian of Records:<br />
                                    V. Mills, at the office of production:<br />
                                    2600 W. Olive. Ste 564., Burbank, CA 91505</td>
                            </tr>
                            <tr>
                                <td>Temptation<br />
                                    J. Garfinkel, Custodian of Records:<br />
                                    18716 Oxnard St., Room 1308,<br />
                                    Tarzana, CA 91356</td>
                                <td>Lord Perious<br />
                                    J. McKone, Custodian of Records:<br />
                                    3780 N. Wike Rd.,<br />
                                    Rolling Meadows, IL 60008</td>
                                <td>Coast To Coast<br />
                                    M. Rothstein, Custodian of records<br />
                                    318 W. 39th St.<br />
                                    New York, NY 10018</td>
                            </tr>
                            <tr>
                                <td>Gentlemens Video<br />
                                    Michael Esposito, Custodian of Records<br />
                                    21638 Lassen Street<br />
                                    Chatsworth, CA 91389</td>
                                <td>Powersville<br />
                                    Proof of Age is on file with<br />
                                    J. Lane, 9730 Variel Ave.,<br />
                                    Chatsworth, CA 91311</td>
                                <td>LBO Entertainment<br />
                                    Robert Geneva, Custodian of Records<br />
                                    21601 Devonshire St. Suite 125<br />
                                    Chatsworth, CA 91311</td>
                            </tr>
                            <tr>
                                <td>RecoRobert Genova<br />
                                    Custodian of Records<br />
                                    2160 Devonshire St. , Suite 125<br />
                                    Chatsworth, CA 91311</td>
                                <td>Bobby Rinaldi<br />
                                    Custodian of Records<br />
                                    Wildlife Productions<br />
                                    8501 Canoga Ave, Unit 8<br />
                                    Canoga Park, Ca 91304</td>
                                <td>E. EICHLER<br />
                                    3107 AYCRIGG AVENUE<br />
                                    PASSAIC, NJ 07055</td>
                            </tr>
                            <tr>
                                <td>M. Weston<br />
                                    Custodian of Records<br />
                                    8471 Canoga Ave<br />
                                    Canoga Park, CA 91304</td>
                                <td>J. Lane<br />
                                    Custodian of Records<br />
                                    Multimedia Pictures<br />
                                    9555 Owensmouth, Suite 9<br />
                                    Chatsworth, CA 91311</td>
                                <td>J. Richman and B. Mendelson<br />
                                    8955 Fulbright Ave.<br />
                                    Chatsworth, CA 91311</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
    );   
}

export { Records_Page }