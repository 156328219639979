import React, { useEffect, useState } from 'react';
import PlaylistService from "../services/Playlist.service";
import { AdListing } from "../components/AdListing";

function Playlists_Page(props) {

    const [playlists, setPlaylists] = useState([]);
    const [loadingPlaylists, setLoadingPlaylists] = useState(true);

    useEffect(() => {
        loadPlaylists();
    }, []);
            
    async function loadPlaylists() {
        const data = await PlaylistService.getAll();
        setPlaylists(data);
        setLoadingPlaylists(false);
    }

    function renderPlaylistsTable(thesePlaylists) {
        return (
            <div >
                {thesePlaylists.filter(x => x.playlistMovies.length>4).map(playlist =>
                    <div key={"playlistHolder_" + playlist.id}>
                        <h4>{playlist.name}</h4>
                            <div className="cards" key={"playlistPreviewHolder_" + playlist.id}>
                                
                                    {playlist.playlistMovies.slice(0, 5).map(plEntity =>                                        
                                        <div key={"playlistPreviewCard_" + plEntity.movie.id} className="card cursor" alt="View playlist" title="View playlist" onClick={() => document.location.href = "/playlist/" + playlist.id} style={{backgroundImage: `url(${plEntity.movie.thumbnail})`}}>
                                            <span className="fa-stack fa-3x">
                                                <i className="fas fa-circle fa-stack-2x"></i>
                                                <i id="icon" className="fas fa-robot fa-stack-1x fa-inverse"></i>
                                            </span>
                                        </div>
                                    )}                           

                            </div>
                        <br />
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-sm-9 col-12">
                <h2 id="tabelLabel">Playlists</h2>
                <p>Check out playlists curated by Prohibitus Staff and Premium Prohibitus Members. Yes, you read that right. Members of our merry gang can manage their very own lists!</p>
                {loadingPlaylists ? <p><em>Loading Playlists...</em></p> : renderPlaylistsTable(playlists)}               
            </div>
            <div className="col-sm-3 col-12">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>   
        </div>
    );
}

export { Playlists_Page };