import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import authService from './../components/api-authorization/AuthorizeService';
import { AdListing } from "../components/AdListing";
import { VideoListing } from "../components/VideoListing";
import { ArrowDownCircleFill } from 'react-bootstrap-icons';
import { Spinner } from 'reactstrap';

function Toplists_Page(props) {

    const params = useParams();
    const [toplistTitle, setToplistTitle] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        params.toplisttype ? toplistView(params.toplisttype) : toplistView("mostviewed");
        loadToplistPage();
    }, []);

    async function toplistView(toplist) {

        switch (toplist) {
            case "mostviewed":
                setToplistTitle("Most Viewed Videos");
                break;
            case "highestrating":
                setToplistTitle("Highest Rated Videos");
                break;
            case "mostrated":
                setToplistTitle("Most Rated Videos");
                break;
            case "mostcommented":
                setToplistTitle("Most Commented Videos");
                break;
            default:
                setToplistTitle("Most Viewed Videos");
        }
    }

    async function loadToplistPage() {
        var curPage = currentPage;
        setPages(existingItems => {
            return [...existingItems, <VideoListing listingType={params.toplisttype} listingFilter="4" listingAmount={12} listingCols={3} listingPage={curPage} />]
        })
        setCurrentPage(curPage + 1);
    }

    return (
        <div className="row">
            <div className="col-sm-9 col-12">
                <h1>Toplists - {toplistTitle}</h1>

                <a href="/toplists/mostviewed">Most Viewed</a>&nbsp;|&nbsp;
                <a href="/toplists/highestrating">Highest Rating</a>&nbsp;|&nbsp;
                <a href="/toplists/mostcommented">Most commented</a>&nbsp;|&nbsp;
                <a href="/toplists/mostrated">Most Rated</a>

                <br /><br />

                <h2>{toplistTitle}</h2>
                {pages.map(e =>
                    <div>{e}</div>
                )}

                <button className="btn-primary floatRight" onClick={() => loadToplistPage()}><ArrowDownCircleFill className="btnIcon" /> Show me more from this toplist!</button>
            </div>
            <div className="col-sm-3 col-12">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>      
        </div>
    );
}

export { Toplists_Page }