class CommentDataService
{
    async getCommentsByVideo(videoId) {
        const response = await fetch("/api/videocomment/getCommentsByVideo?videoId=" + videoId);
        return await response.json();
    }

    async getBymember(memberId) {
        const response = await fetch("/api/videocomment/getbymember?memberId=" + memberId);
        return await response.json();
    } 

    async addCommentToVideo(videoId, comment, parentCommentId, token) {
        if (parentCommentId === "" | parentCommentId === undefined) {
            parentCommentId = "00000000-0000-0000-0000-000000000000";
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ "videoId": videoId, "videocomment": comment, "ParentCommentId": parentCommentId })
        };
        const response = await fetch('/api/videocomment/addCommentToVideo', requestOptions);
        return await response.json();
    }

    async reportComment(commentId, feedback, token) {
        
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ "commentId": commentId, "feedback": feedback })
        };
        const response = await fetch('/api/videocomment/reportComment', requestOptions);
        return await response.json();
    }

    async removeCommentById(id, token) {
        const response = await fetch("/api/videocomment/removeCommentById?id=" + id, { headers: { 'Authorization': `Bearer ${token}` } });
        return await response.json();
    }
}

export default new CommentDataService();