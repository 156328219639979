import React from 'react';
import { AdListing } from "../components/AdListing";
import toast, { Toaster } from 'react-hot-toast';

function ForgotPassword_Page (props)
{
    async function forgotPassword() {
        const email = document.getElementById("email").value;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "Email": email })
        };
        const response = await fetch('/api/videocomment/forgotPassword', requestOptions);
        toast("A reminder has been sent to your e-mail address")
        return await response.json();
    }

    return (
        <div className="row">
            <div className="col-6">
                <Toaster/>
                <h2>Oh poop, I forgot my password</h2>
                <p>Thanks for confirming your e-mail address!You can now sign in and start doing cool things only members can do!</p>
                <div className="row">
                    <div className="col-3">E-mail</div>
                    <div className="col-9"><input type="email" id="email" className="form-control prohibitusInput" /></div>
                </div>
                <div className="row">
                    <div className="col-12"><button className="w-100 btn btn-lg btn-primary floatRight" onClick={() => forgotPassword()}>Remind me</button></div>
                </div>
            </div>
            <div className="col-3">
                <h2>Reasons to forgive yourself</h2>
                <ul>
                    <li>You made up a crazy complicated password, which was a bit too good to remember even though they should be complicated</li>
                    <li>Your goldfish may have eaten the password</li>
                    <li>You may not even have a goldfish</li>
                </ul>
            </div>
            <div className="col-3">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>            
        </div>
    );
}

export { ForgotPassword_Page };