class AdDataService {

    async getAll() {
        const response = await fetch("/api/ad/getall");
        return await response.json();
    }

    async get(url) {
        const response = await fetch("/api/ad/?url=" + url);
        return await response.json();
    }

    async logAdview(id,url,action) {
        const response = await fetch("/api/ad/logadview?id=" + id + "&action=" + action + "&url=" + url);
        return await response.json();
    }
}

export default new AdDataService();