import React, { useState } from 'react';
import CookieConsent from "react-cookie-consent";
import RTAlogo from './../../assets/media/rta.gif';
import { triggerCookieConsentBanner, CookieConsentBanner } from "@porscheofficial/cookie-consent-banner-react";

const initConsent = ({ acceptedCategories }) => {

    if (acceptedCategories.includes("analytics")) {
         <script id="tagmanager-script" key="tagmanager-script">
                    // Load tag manager in the future
         </script>        
    }
};

function Footer (props) {
    const [acceptedCategories, setAcceptedCategories] = useState([]);
    const [introText, setIntroText] = useState("To provide you with the best possible experience on our website, we may use cookies as described in our Cookie Policy. You can enable or disable optional cookies as desired in the cookie configurations.");
    const [introCookieText, setIntroCookieText] = useState(<div>To provide you with the best possible experience on your website, we may use cookies as described in our <a href="/cookiepolicy">Cookie Policy</a>. By clicking "Agree & Continue" you consent to the use of such cookies.</div>);

    return (
        <footer className="footer">
            <CookieConsentBanner
                handlePreferencesUpdated={initConsent}
                handlePreferencesRestored={initConsent}
                btnLabelAcceptAndContinue="Agree and continue"
                btnLabelSelectAllAndContinue="Agree & Continue With All Selected"
                btnLabelOnlyEssentialAndContinue="Agree & Continue With Required Cookies"
                btnLabelPersistSelectionAndContinue="Agree & Continue With Selection"
                contentSettingsDescription={introText}
                availableCategories={[
                    {
                        description:
                            "Enable you to navigate and use the basic functions and to store preferences.",
                        key: "technically_required",
                        label: "Necessary cookies",
                        isMandatory: true,
                    },
                    {
                        description:
                            "Enable us to determine how visitors interact with our service in order to improve the user experience.",
                        key: "analytics",
                        label: "Analytics cookies",
                    },
                    {
                        description:
                            "These cookies are needed to provide the performance and functionality of our Site and monitor system availability, stability, and error reporting",
                        key: "performance_functionality",
                        label: "Performance / Functionality cookies",
                    },
                ]}
            >
                <h5>Privacy Overview</h5>
                {introCookieText}
                <br/>
                <button id="btnCookieConfig" className="btn-primary"
                    onClick={(e) => {
                        setIntroCookieText("")
                        document.getElementById("btnCookieConfig").style.display = 'none';
                        triggerCookieConsentBanner({ showDetails: true });
                    }}
                    onKeyPress={() => {
                        triggerCookieConsentBanner({ showDetails: true });
                    }}
                    type="button"
                >See Cookie Configurations</button>
            </CookieConsentBanner>
            
            <CookieConsent
                location="top"
                overlay={false}
                buttonText="ACCEPT"
                cookieName="ProhibitusAge"
                style={{ background: "#2B373B", margin: "0px !important" }}
                buttonStyle={{ color: "#ffffff", fontSize: "13px", background: "linear-gradient(var(--btn-background-color-hover), #000000)" }}
                expires={150}
                style={{
                    background: "linear-gradient(#A00A3A, #630021)",
                }}
            >You need to be 18 years or older to visit this site</CookieConsent>
            <a className="footerTxt" href="#">BETA Testing Phase</a> &nbsp;| &nbsp;
            <a className="footerTxt" href="/sitemap">Sitemap</a> &nbsp;| &nbsp;
            <a className="footerTxt" href="/contactus">Contact Us</a> &nbsp;| &nbsp;
            <a className="footerTxt" href="/termsandconditions">Terms & Conditions</a> &nbsp;|  &nbsp;
            <a className="footerTxt" href="/cookiepolicy">Cookie Policy</a> &nbsp;| &nbsp;
            <a className="footerTxt" href="/privacypolicy">Privacy Policy</a> &nbsp;|  &nbsp;
            <a className="footerTxt" href="/dmca">DMCA</a> &nbsp;|  &nbsp;
            <a className="footerTxt" href="/2257">2257</a>
            <a href="http://rtalabel.org/?content=validate&rating=RTA-5042-1996-1400-1577-RTA" target="_blank" alt="Verified RTA member" title="Verified RTA member"><img src={RTAlogo} alt="Verified RTA member" title="Verified RTA member" className="d-inline-block align-middle footerLogo" /></a>
            <div className="footerBottom"></div>
        </footer>
    );
}

export { Footer }