import React, { useEffect, useState } from 'react';
import { AdListing } from "../components/AdListing";
import { HeartFill } from 'react-bootstrap-icons';

function ContactUs_Page()
{    
    return (
      <div>
        <div className='row'>
            <div className='col-sm-9 col-12'>
                <h2>Contact us</h2>
                    <p>Have questions, thoughts or fun ideas and suggestions? Fire away a message to us at hello[a]prohibitus.com! <br />
                        Oh, and please be patient for our answer, there are a lot of fun things in our inbox and we want to make sure to address as much as possible! <HeartFill className="icon" />
                        <br /><br />
                        /The Prohibitus Crew
                    </p>
            </div>
            <div className="col-sm-3 col-12">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>            
        </div>
      </div>
    );
}

export { ContactUs_Page };