import React from 'react';

function DMCA_Page (props) {

    return (
        <div className="row">
            <div className="col-12">
                <h2>DMCA Notice</h2>
                <p>
                    Last Updated: 2022-11-13.<br /><br />
                    Prohibitus (hereinafter "we", "us" or "our") respects the intellectual property rights of others and expects all users to do likewise. If you believe your copyright-protected work was posted on prohibitus.com without your permission, Prohibitus provides a mechanism for you to submit copyright notifications under the Digital Millennium Copyright Act ("DMCA"). If you receive notice that a work you posted was taken down and you believe such take-down was improper, Prohibitus provides a mechanism for you to submit a counter-notice under the DMCA.
                    CAUTION: THERE ARE LEGAL CONSEQUENCES FOR IMPROPERLY POSTING CONTENT FOR WHICH YOU DO NOT HAVE RIGHTS AS WELL AS FOR SUBMITTING AN IMPROPER NOTICE OR COUNTER-NOTICE UNDER THE DMCA. IF YOU ARE UNSURE WHETHER YOU HOLD RIGHTS TO A PARTICULAR WORK OR IF A WORK YOU BELIEVE IS IMPROPERLY POSTED MAY OTHERWISE BE PERMITTED BY LAW, SUCH AS UNDER THE "FAIR USE" DOCTRINE, PLEASE CONSULT AN ATTORNEY OR ANOTHER LEGAL ADVISOR.
                    PROCEDURE FOR DELIVERING DMCA NOTICE:<br /><br />
                    If you believe that content posted on prohibitus.com infringes your copyright and no legal exception exists,
                    you may submit a DMCA copyright infringement notice to us. Provide the following information via email<br /><br />
                    Email for incoming DMCA notices: dmca@prohibitus.com
                    <ul>
                        <li>Identification of the copyrighted work or works claimed to have been infringed (a clear description of the materials allegedly being infringed upon);</li>
                        <li>Identification of the infringing material and information reasonably sufficient to permit Prohibitus to locate the materials to be removed or access to which is to be disabled, including, if applicable, the URL on the Site where such material may be found;</li>
                        <li>Your contact information, including your address, telephone number, and an email address; A statement that you have a good faith belief that the use of the material in the manner asserted is not authorized by the copyright owner, its agent, or the law; and</li>
                        <li>A statement, under penalty of perjury, that the information in the notification is accurate and that you are authorized to act on behalf of the copyright owner.</li>
                        <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed.</li>
                    </ul>

                    If we receive a takedown notice in accordance with the foregoing, we will remove or disable access to the material identified in the notice and attempt to
                    notify any user who uploaded the allegedly infringing material. Any such user will have the opportunity to submit a "counter-notice" as set forth below.
                    Prohibitus maintains a repeat infringer policy and terminates the accounts of repeat infringers.<br /><br />

                    Procedure for delivering DMCA counter-notice:<br /><br />

                    If any user believes that any material removed pursuant to a DMCA Notice is either not infringing or that such user otherwise has the right to post and use such
                    material from the copyright owner, the copyright owner's agent, or pursuant to the law, the user may send a counter-notice containing the following information to
                    the Designated Agent listed below:<br /><br />

                    <ul>
                        <li>A physical or electronic signature of the user;</li>
                        <li>Identification of the material that has been removed and the location at which the material appeared before it was removed;</li>
                        <li>A statement that the user has a good faith belief that the material was removed as a result of mistake or a misidentification of the material; and</li>
                        <li>The user's name, address, telephone number, and, if available, e-mail address and a statement that such person or entity consents to the jurisdiction
                            of the judicial district in which Prohibitus is located, and that such person or entity will accept service of process from the person who provided
                            notification of the alleged infringement.</li>
                    </ul>

                    If a counter-notice is received by the Designated Agent, we will attempt to forward a copy of the counter-notice to the original complaining party. The original complaining party will have 10 days to file a legal action against the allegedly infringing party and notify us that such action has been instituted. If we do not receive any such notification, we may restore the material, at our discretion.
                </p>
            </div>
         </div>
    );   
}

export { DMCA_Page }