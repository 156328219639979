class CategoryDataService {
    async getAll() {
        const response = await fetch('/api/category/');
        return await response.json();
    }

    async get(id) {
        const response = await fetch("/api/category/" + id);
        return await response.json();
    }
}

export default new CategoryDataService();