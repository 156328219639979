import React, { useEffect, useState } from 'react';
import AdsService from "../services/Ad.service";

// General listing of ads
function AdListing(props) {
    const [ads, setAds] = useState([]);
    const [loadingAds, setLoadingAds] = useState(true);

    useEffect(() => {
        loadAds();
    }, []);

    async function loadAds() {
        if (props.url == "") {
            var tmpAds = await AdsService.get(props.url);
            setAds(tmpAds);
            setLoadingAds(false);
        } else {
            var tmpAds = await AdsService.getAll();
            setAds(tmpAds);
            setLoadingAds(false);
        }
    }

    async function logAdView(theseAds, action) {
        theseAds.map(ad => { AdsService.logAdview(ad.id, props.url, action) });      
    }

    async function logSpecificAdView(id, action) {
        AdsService.logAdview(id, props.url, action);
    }

    function renderAdsListing(theseAds) {

        if (!theseAds || theseAds.length === 0) {
            return "";
        }

        logAdView(theseAds, "view");       

        return (
            <div>
                {theseAds.slice(0, props.amount).map(ad =>
                    <a href={ad.url} alt={ad.name} title={ad.name} key={"movieAdLink_" + ad.id} onClick={() => logSpecificAdView(ad.id, "click")}>
                        <img src={ad.source} alt={ad.name} title={ad.name} className="adImg" />
                    </a>
                )}
            </div>
        );
    }

    return (
        <div>
            {loadingAds ? <p><em>Loading our magical ads...</em></p> : renderAdsListing(ads)}
        </div>
    );
}

export { AdListing };