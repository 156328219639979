class FavouriteDataService {
    async getAll() {
        const response = await fetch('/api/favourite/');
        return await response.json();
    }

    async get(id) {
        const response = await fetch("/api/favourite/" + id);
        return await response.json();
    }

    async getBymember(memberId) {
        console.log(memberId);
        const response = await fetch("/api/favourite/getbymember?memberId=" + memberId);
        return await response.json();
    } 

    async favourite(videoId, token) {
        const response = await fetch("/api/favourite/favourite?videoId=" + videoId, { headers: { 'Authorization': `Bearer ${token}` } });
        return await response.json();
    }

    async getByUser(token) {
        const response = await fetch("/api/favourite/getbyuser", { headers: { 'Authorization': `Bearer ${token}` } });
        return await response.json();
    }
}

export default new FavouriteDataService();