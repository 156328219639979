import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import authService from './../components/api-authorization/AuthorizeService';
import { AdListing } from "../components/AdListing";
import { VideoListing } from "../components/VideoListing";

function MyToplists_Page(props) {

    const [authenticated, setAuthenticated] = useState([false]);
    const params = useParams();
    const [toplistTitle, setToplistTitle] = useState([]);

    useEffect(() => {
        populateState();
        console.log(params)
        params.toplisttype ? toplistView(params.toplisttype) : toplistView("mymostviewed");
    }, []);

    async function populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        if (user) {
            setAuthenticated(isAuthenticated);
        }
    }

    async function toplistView(toplist) {

        switch (toplist) {
            case "mymostviewed":
                setToplistTitle("My Most Viewed Videos");
                break;
            case "myhighestrating":
                setToplistTitle("My Highest Rated Videos");
                break;
            default:
                setToplistTitle("My Most Viewed Videos");
        }
    }

    function returnRegisterBox() {
        return <div><h4>As a member you have your very own toplists!</h4>
            <h4>Membership Perks</h4>
            Why join you ask!
            <ul>
                <li>Because...it's cool...obviously!</li>
                <li>Manage your own playlists</li>
                <li>Rate our videos</li>
                <li>Have your own favebox with favourite videos</li>
                <li>Comment videos together with other peeps</li>
                <li>Check out your very own toplists</li>
                <li>Premium Content</li>
                <li>Oh so much more to come!</li>                
            </ul><h4><a href="/authentication/register">Sign up here to join our merry gang!</a></h4></div>
    }

    return (
        <div className="row">
            <div className="col-sm-9 col-12">
                <h1>My Toplists - {toplistTitle}</h1>

                <a href="/mytoplists/mymostviewed">My Most Viewed Vids</a>&nbsp;|&nbsp;
                <a href="/mytoplists/myhighestrating">My Highest Rated Vids</a>&nbsp;

                <br /><br />
                {authenticated === true ? <VideoListing listingType={params.toplisttype} listingFilter="4" listingAmount={8} listingCols={3} /> : returnRegisterBox()}                
            </div>
            <div className="col-sm-3 col-12">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>      
        </div>
    );
}

export { MyToplists_Page }