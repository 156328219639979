import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Actor_Page } from "./pages/Actor";
import { Actors_Page } from "./pages/Actors";
import { Category_Page } from "./pages/Category";
import { Videos_Page } from "./pages/Videos";
import { Video_Page } from "./pages/Video";
import { Home_Page } from "./pages/Home";
import { SignIn_Page } from "./pages/SignIn";
import { SignUp_Page } from "./pages/SignUp";
import { SiteMap_Page } from "./pages/SiteMap";
import { TermsAndConditions_Page } from "./pages/TermsAndConditions";
import { CookiePolicy_Page } from "./pages/CookiePolicy";
import { PrivacyPolicy_Page } from "./pages/PrivacyPolicy";
import { DMCA_Page } from "./pages/DMCA";
import { Records_Page } from "./pages/Records";
import { Playlist_Page } from './pages/Playlist';
import { MyPlaylists_Page } from './pages/MyPlaylists';
import { MyFavourites_Page } from './pages/MyFavourites';
import { Playlists_Page } from './pages/Playlists';
import { Toplists_Page } from './pages/Toplists';
import { MyToplists_Page } from './pages/MyToplists';
import { Wizard_Page } from './pages/Wizard';
import { Profile_Page } from './pages/Profile';
import { ConfirmEmail_Page } from './pages/ConfirmEmail';
import { ForgotPassword_Page } from './pages/ForgotPassword'; 
import { ResetPassword_Page } from './pages/ResetPassword';
import { Dashboard_Page } from './pages/Dashboard';
import { ContactUs_Page } from './pages/ContactUs';

const AppRoutes = [
    {
        index: true,
        element: <Home_Page />
    },
    {
        path: '/signin',
        requireAuth: false,
        element: <SignIn_Page />
    },
    {
        path: '/signup',
        requireAuth: false,
        element: <SignUp_Page />
    }, 
    {
        path: '/forgotpassword',
        requireAuth: false,
        element: <ForgotPassword_Page />
    },
    {
        path: '/resetpassword/:code',
        requireAuth: false,
        element: <ResetPassword_Page />
    },
    {
        path: '/confirmemail',
        requireAuth: false,
        element: <ConfirmEmail_Page />
    },
    {
        path: '/contactus',
        requireAuth: false,
        element: <ContactUs_Page />
    }, 
    {
        path: '/dashboard',
        requireAuth: true,
        element: <Dashboard_Page />
    },
    {
        path: '/actors',
        requireAuth: false,
        element: <Actors_Page />
    },
    {
        path: '/actor/:actorName',
        requireAuth: false,
        element: <Actor_Page />
    },
    {
        path: '/videos',
        requireAuth: false,
        element: <Videos_Page />
    },
    {
        path: '/video/:videoId/:videoName',
        requireAuth: false,
        element: <Video_Page />
    },
    {
        path: '/category/:categoryid/:categoryname',
        requireAuth: false,
        element: <Category_Page />
    },
    {
        path: '/wizard',
        requireAuth: false,
        element: <Wizard_Page />
    },
    {
        path: '/sitemap',
        requireAuth: false,
        element: <SiteMap_Page />
    },
    {
        path: '/termsandconditions',
        requireAuth: false,
        element: <TermsAndConditions_Page />
    },
    {
        path: '/privacypolicy',
        requireAuth: false,
        element: <PrivacyPolicy_Page />
    },
    {
        path: '/cookiepolicy',
        requireAuth: false,
        element: <CookiePolicy_Page />
    },
    {
        path: '/dmca',
        requireAuth: false,
        element: <DMCA_Page />
    },
    {
        path: '/2257',
        requireAuth: false,
        element: <Records_Page />
    },
    {
        path: '/toplists',
        requireAuth: false,
        element: <Toplists_Page />
    },
    {
        path: '/mytoplists',
        requireAuth: false,
        element: <MyToplists_Page />
    },
    {
        path: '/mytoplists/:toplisttype',
        requireAuth: false,
        element: <MyToplists_Page />
    },
    {
        path: '/toplists/:toplisttype',
        requireAuth: false,
        element: <Toplists_Page />
    },
    {
        path: '/myfavourites',
        requireAuth: false,
        element: <MyFavourites_Page />
    },
    {
        path: '/myplaylists',
        requireAuth: false,
        element: <MyPlaylists_Page />
    },
    {
        path: '/playlists',
        requireAuth: false,
        element: <Playlists_Page />
    },
    {
        path: '/playlist/:playlistid',
        requireAuth: false,
        element: <Playlist_Page />
    },
    {
        path: '/playlist/:playlistid/:videoid',
        requireAuth: false,
        element: <Playlist_Page />
    },
    {
        path: '/member/:memberId/:memberName',
        requireAuth: false,
        element: <Profile_Page />
    }, 
  ...ApiAuthorzationRoutes
];

export default AppRoutes;
