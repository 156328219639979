import React, { useEffect, useState } from 'react';
import { AdListing } from "../components/AdListing";

function ConfirmEmail_Page (props)
{
    useEffect(() => {
        
    }, []);

    return (
        <div className="row">
            <div className="col-sm-9 col-12">
                <h2>E-mail Address Confirmed</h2>
                <p>Thanks for confirming your e-mail address!You can now sign in and start doing cool things only members can do!</p>
                <h4><a href="signin">Why wait? Sign in!</a> </h4>      
            </div>
            <div className="col-sm-3 col-12">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>            
        </div>
    );
}

export { ConfirmEmail_Page };