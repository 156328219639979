import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { VideoListing } from "../components/VideoListing";
import { AdListing } from "../components/AdListing";
import { ArrowDownCircleFill } from 'react-bootstrap-icons';

function Category_Page(props)
{
    const params = useParams();
    const [currentPage, setCurrentPage] = useState(0);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        loadCategoryPage();
    }, []);

    async function loadCategoryPage() {
        var curPage = currentPage;
        setPages(existingItems => {
            return [...existingItems, <VideoListing listingType="categorylisting" listingFilter={params.categoryid} listingAmount={12} listingPage={curPage} />]
        })
        setCurrentPage(curPage+1);
    }

    return (
        <div className="row">
            <div className="col-sm-9 col-12">
                <h2>{params.categoryname}</h2>
                {pages.map(e =>
                    <div>{e}</div>
                )}
                <button className="btn-primary floatRight" onClick={() => loadCategoryPage()}><ArrowDownCircleFill className="btnIcon" /> Show me more from this category!</button>
            </div>
            <div className="col-3 mobileHide">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} amount={3} />
            </div>            
      </div>
    );
}

export { Category_Page };