import React, { useEffect, useState } from 'react';
import ActorService from "../services/Actor.service"
import { AdListing } from "../components/AdListing";

function Actors_Page()
{
    const [actors, setActors] = useState([]);
    const [loadingActors, setLoadingActors] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        loadActors();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const isMobile = width <= 768;

    async function loadActors() {
        var tmpActor = await ActorService.getAll();
        console.log(tmpActor)
        setActors(tmpActor);
        setLoadingActors(false);
    }

    function renderActor(actor, count) {
        if (isMobile) {
            if (count % 4 === 2) {
                console.log("Ad" + count)
                return <AdListing amount={1} />
            }
            else {
                return <div className="divActorListing" key={"actorCard_" + actor.wmcExternalId}>
                    <a className="actorLink" href={"/actor/" + actor.firstName + "%20" + actor.lastName} key={"actorLink_" + actor.wmcExternalId}>
                        <img src={actor.profilePicture} className="actorProfilePicture" /><br />
                        {actor.firstName + " " + actor.lastName}
                    </a>
                </div>

            }
        } else {
            return <div className="divActorListing" key={"actorCard_" + actor.wmcExternalId}>
                <a className="actorLink" href={"/actor/" + actor.firstName + "%20" + actor.lastName} key={"actorLink_" + actor.wmcExternalId}>
                    <img src={actor.profilePicture} className="actorProfilePicture" /><br />
                    {actor.firstName + " " + actor.lastName}
                </a>
            </div>
        }
    }

    function renderActorsListing(theseActors) {
        return (
            <div className='row'>
                {theseActors.map((actor,count) =>
                    <div className="col-md-2 col-sm-2 col-xs-2" key={"movieHolder_" + actor.wmcExternalId}>
                        <div className="divActorListing" key={"movieCard_" + actor.wmcExternalId}>
                            {renderActor(actor, count)}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return (
      <div>
        <div className='row'>
            <div className='col-sm-9 col-12'>
                <h2>Stars</h2>
                <p>Here is a listing of our fabulous stars...and this list will only keep on growing, because we love this stuff and will keep on collecting!</p>
                {loadingActors ? <p><em>Loading our list of fabulous stars...</em></p> : renderActorsListing(actors)}
            </div>
            <div className="col-sm-3 col-12">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>            
        </div>
      </div>
    );
}

export { Actors_Page };