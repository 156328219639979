import React from 'react';

function PrivacyPolicy_Page(props) {

    return (
        <div className="row">
            <div className="col-12">
                <h2>Privacy Policy</h2>
                <p>Last Modified 2022-11-13</p>
                <h3>Introduction</h3>
                <p>Prohibitus (hereinafter "we", "us" or "our") respects your right to privacy and takes our responsibilities in relation to the processing
                    of personal data seriously. We do not collect or process personal data unnecessarily. This privacy policy (the "Policy") together with
                    our terms of service (the "Terms of Service") sets out important information about your rights in relation to the processing of your personal data,
                    and the basis on which any personal data we collect from you, or that you provide to us, will be processed in connection with your use of
                    the https://www.prohibitus.com/ website ("our Site" or "the Site")</p>

                   <h3>Controller</h3> 
                    <p>Under this Policy, and unless the circumstances otherwise require, we will be what's known under the General Data Protection Regulation (EU)
                    2016/679 (the "GDPR") as the "controller" of the personal data you provide to us. This means that we determine the purposes and means of the
                    processing of personal data we collect.</p>

                    <h3>Scope</h3>
                    <p>This privacy policy applies to information we collect:
                    <ul>
                        <li>on the Websites and your email communications with the Websites,</li>
                        <li>when you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this privacy policy.</li>
                    </ul>
                    </p>

                    <h3>Information you give us</h3>
                    <p>Your Data. This is information about you which is consensually given by making a registration on our Site.
                    The information you give us may include:
                    <ul>
                        <li>Identity Data: your username and e-mail address;</li>
                    </ul>
                    </p>

                    <h4>The Data We Collect About You</h4>
                    <p>
                        We may collect different kinds of personal data about you, depending on whether you chose to create an account with us.
                        Automatically Collected Information. With regard to each of your visits to our Site we will automatically collect the following information:
                        <ul>
                            <li>Technical Data: technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, browser type, and version, time zone setting, browser plug-in types and versions, operating system and platform, how often you visit the Site and other performance data;</li>
                            <li>Usage Data: information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through, and from our site (including date and time), page response times, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page</li>
                        </ul>

                        <b>No special categories of personal data:</b> We do not require or collect any personal data that is your sensitive personal data or any special
                        category of personal data under the GDPR
                    </p>
                    
                    <h4>What we do with your information</h4>

                    We will only use your personal information when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
                    <ul>
                        <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
                        <li>Where it is necessary for our legitimate interest (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
                        <li>Where we need to comply with a legal or regulatory obligation.</li>
                    </ul>
                   
                    <h4>How long we keep your information</h4>
                    We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. This means that the period of time for which we store your personal data may depend on the type of data we hold.
                    <br /><br />

                    <h4>Disclosure of your information</h4>
                    We do not share your personal information to third parties for marketing purposes without your explicit consent. To provide you with our services and to also meet our regulatory requirements, we may need to share your information with our business partners, suppliers, and sub-contractors, affiliated companies and other third party product and service providers.
                <br /><br />
                    <h4>Security Measures</h4>
                    We take our security responsibilities seriously, using the most appropriate physical and technical measures, and require our partners to use the same standard of care. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access. These are described in more detail below.
                <br /><br />
                    <h4>Location of Servers and Accessibility</h4>
                    Your personal data is not stored locally but on a secure server. which helps ensure your persona data is secure and private.
                <br /><br />
                    <h4>Data Storage</h4>
                    Your personal data is stored on secure servers hosted on Vicetemple. These servers are located in the Amsterdam.  Vicetemple comply with Standard Contractual Clauses as a valid mechanism for transferring data outside the European Union.
                <br /><br />
                    <h4>Data Encryption</h4>
                    Data is encrypted using SSL Certification when transmitted from our servers to your browser.
                <br /><br />
                    <h4>Your Personal Data and Your Rights</h4>
                    <h5>Accessing your Personal Data</h5>

                    You may request access at any time to a copy of the personal data we hold about you. Any such request should be submitted to us in writing and sent to support@prohibitus.com  We will need to verify your identity in such circumstances and may request more information or clarifications from you if needed to help us locate and provide you with the personal data requested. There is usually no charge applied to access your personal data (or to exercise any of the other rights). However, if your request is clearly unfounded, repetitive, or excessive, we may charge a reasonable fee. Alternatively, we may refuse to comply with your request in these circumstances.
                <br /><br />
                    <h5>Right of Restriction</h5>
                    You may restrict us from processing your personal data in any of the following circumstances:<br />
                    <ul>
                        <li>You have contested the accuracy of the personal data we hold on record in relation to you or for a period of time to enable us to verify the accuracy of the personal data;</li>
                        <li>The processing of your personal data is unlawful and you request the restriction of the use of personal data instead of its erasure;</li>
                        <li>We no longer require your personal data for the purpose of processing but you require this data for the establishment, exercise, or defense of legal claims; or</li>
                        <li>Where you have contested the processing (under Article 21(1) of the GDPR) pending the verification of our legitimate grounds.</li>
                    </ul>

                    <h5>Corrections or Erasure (Right to Rectification and Right to Be Forgotten)</h5>
                    If we hold personal data concerning you which are no longer necessary for the purposes for which they were collected or if you withdraw consent for us to process your personal data, you can request the deletion of this personal data. This right, however, will not apply where we are required to process personal data in order to comply with a legal obligation or where the processing of this information is carried out for reasons of public interest in the area of public health. If the personal information we hold about you is inaccurate, you may request to have your personal information updated and corrected.
                    <br /><br />

                    <h5>Your Right to Object</h5>
                    You have the right to object to the processing of your personal data at any time:
                    <ul>
                        <li>For direct marketing purposes</li>
                        <li>For profiling, to the extent, it relates to direct marketing</li>
                        <li>Where we process your personal data for the purposes of legitimate interests pursued by us, except where we can demonstrate compelling legitimate grounds for this processing which would override your interests, rights, and freedoms or in connection with the enforcement or defense of a legal claim</li>
                    </ul>
                    <br/>
                    Should this occur, we will no longer process your personal data for these purposes unless doing so is justified by a compelling legitimate ground as described above.
                <br /><br />
                    <h5>Data Portability</h5>
                    Where we process your personal data by automated means (i.e., not on paper) and this processing is based on your consent or required for the performance of a contract between us, you have the right to request from us a copy of your personal data in a structured, commonly used machine-readable format and, where technically feasible, to request that we transmit your personal data in this format to another controller.
                <br /><br />
                    <h5>Profiling</h5>
                    Profiling is an automated form of processing of personal data often used to analyze or predict the personal aspects of an individual person. This could relate to a person's performance at work, economic situation, health, personal preferences, reliability, behavior, location, or movements. An example of this would be where a bank uses an automated credit scoring system to assess and reject a loan application.
                    You have the right to be informed if your personal data will be subject to automated decision making, including profiling. You also have the right not to be subject to a decision based solely on the automated process, including profiling, where that decision impacts on your legal rights. There are some exceptions to this rule, where, for example, the decision is necessary for connection with the performance of a contract between us, is authorized by law, or where you have given your explicit consent to this automated processing. In this case, however, we do not engage in profiling or automated processing for profiling purposes.
                <br /><br />
                    <h5>Personal Rights</h5>
                    The rights described in this section are personal rights and are exercisable only by the individual person (or data subject) concerned. If we receive any such request or communication directly from your customers and/or in relation to Your End Customer Data, we will refer the matter to you and cooperate in providing such reasonable assistance as may be required to enable you, as a controller, to respond to the matter. This will be described in more detail in the Terms of Service or the other relevant contract between us.
                <br /><br />
                    <h4>Changes to this policy</h4>
                    Any changes made to this Policy from time to time will be published at the Site.
                    Any material or other change to the data processing operations described in this Policy which is relevant to or impacts on you or your personal data will be notified to you in advance by email. In this way, you will have an opportunity to consider the nature and impact of the change and exercise your rights under the GDPR in relation to that change (e.g., to withdraw consent or to object to the processing) as you see fit.
            </div>
        </div>
    );
}

export { PrivacyPolicy_Page }