import React, { useEffect, useState } from 'react';
import { VideoListing } from "../components/VideoListing";
import { AdListing } from "../components/AdListing";
import { ActorListing } from "../components/ActorListing";
import { PlaylistListing } from "../components/PlaylistListing";
import CategoryService from "../services/Category.service";

function Home_Page(props) {

    const [categories, setCategories] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);

    useEffect(() => {
        loadCategories();
    }, []);

    async function loadCategories() {
        const theseCategories = await CategoryService.getAll();
        setCategories(theseCategories);
        setLoadingCategories(false);
    }

    function renderCategories(theseCategories) {
        let newList = shuffle(theseCategories);
        return (newList.slice(0,16).map((chosenCategory, index) => <div key={"navBarCategoryLI_" + chosenCategory.wmcExternalId} className="homeCategory" onClick={() => document.location.href = "/category/" + chosenCategory.wmcExternalId + "/" + chosenCategory.name}><a href={"/category/" + chosenCategory.wmcExternalId + "/" + chosenCategory.name}>{chosenCategory.name}</a></div>))
    }

    const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);

    return (
        <div>
            <div className="row">
                <div className="col-12">                    
                    <div className="homeCategoryMain">Random Categories</div>
                    {loadingCategories ? "" : renderCategories(categories, 0)}                    
                </div>
            </div>
            <div className="row">
                <div className="col-sm-9 col-12">
                    <h2>Category of the week - Sexy Maid</h2>
                    <VideoListing listingType="categorylisting" listingFilter={70} listingCols={3} listingAmount={8} listingPage={0} />
                    <h2>This weeks Fabulous Stars</h2>
                    <ActorListing amount={4} />
                    <h2>Playlists Being Watched</h2>
                    <PlaylistListing />
                </div>
                <div className="col-3 mobileHide">
                    <h2>Our Partners</h2>
                    <AdListing url={"/video/"} />
                    <h2>This weeks Boobs</h2>
                    <VideoListing listingType="categorylisting" listingFilter={11} listingCols={12} listingAmount={1} listingPage={0} />
                    <h2>This weeks Dick</h2>
                    <VideoListing listingType="categorylisting" listingFilter={7} listingCols={12} listingAmount={1} listingPage={0} />                    
                </div>
            </div>
        </div>
    );   
}

export { Home_Page }