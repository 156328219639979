import React from 'react';

function Wizard_Page (props) {

    return (
        <div>
            <h2>Our Wizard is coming soon</h2>
            Well, we don't want to be just any adult hangout - we want to help you find whatever tickles your fancy out of our awesome selection.
        </div>
    );
}
export { Wizard_Page }