import React from 'react';
import { AdListing } from "../components/AdListing";
import toast, { Toaster } from 'react-hot-toast';

function SignUp_Page(props)
{
    async function signMeUp() {

        const uName = document.getElementById("loginUsername").value;
        const uEmail = document.getElementById("loginEmail").value;
        const uPass = document.getElementById("loginPassword").value;
        const uPassConfirm = document.getElementById("loginPasswordConfirmation").value;

        if (uName === "") {
            alert("Your username is empty");
            return false;
        }

        if (uEmail === "") {
            alert("Your e-mail address is empty");
            return false;
        }

        if (uPass !== uPassConfirm) {
            alert("Passwords do not match");
            return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "Username": uName,
                "Email": uEmail,
                "Password": uPass,
                "PasswordConfirmation": uPassConfirm,
            })
        };

        var response = await fetch('/api/videocomment/signUp', requestOptions)
        if (response.status === 200) {
            toast("Welcome to the gang! Now check your e-mail and confirm your registration!")
            setTimeout(() => { document.location.href = "/" }, 2000); 
        } else {
            if (response.status === 399) {
                toast("Your e-mail already exists, have you forgotten your password?")
            }
            if (response.status === 400) {
                toast("Your username already exists, have you forgotten your password?")
            }
        }  
    }

    return (
        <div>
            <div className="row">
                <div className="col-sm-6 col-12">
                    <h1>Sign Up</h1>   
                    <p>Join our gang of adult entertainment lovers and enjoy a bunch of evergrowing members-only features! And don't worry. We aren't interested in sharing your e-mail address or anything like that. We make our living with ads and such...</p>
                    <div className="row">
                        <div className="col-5">Username (shown on your profile page, with your comments etc)</div>
                        <div className="col-7"><input type="text" id="loginUsername" className="form-control prohibitusInput" /></div>
                    </div>
                    <div className="row">
                        <div className="col-5">E-mail</div>
                        <div className="col-7"><input type="email" id="loginEmail" className="form-control prohibitusInput" /></div>
                    </div>
                    <div className="row">
                        <div className="col-5">Password</div>
                        <div className="col-7"><input type="password" id="loginPassword" className="form-control prohibitusInput" /></div>
                    </div>
                    <div className="row">
                        <div className="col-5">Confirm Password</div>
                        <div className="col-7"><input type="password" id="loginPasswordConfirmation" className="form-control prohibitusInput" /></div>
                    </div>
                    <div className="row">                        
                        <div className="col-12"><button className="w-100 btn btn-lg btn-primary floatRight" onClick={()=>signMeUp()}>Sign Me Up!</button></div>
                    </div>
                    <Toaster />
                </div>
                <div className="col-sm-3 col-12">
                    <h2>Membership Perks</h2>
                    Why join you ask!
                    <ul>
                        <li>Because...it's cool...obviously!</li>
                        <li>Manage your own playlists</li>
                        <li>Rate our videos</li>
                        <li>Have your own favebox with favourite videos</li>
                        <li>Comment videos together with other peeps</li>
                        <li>Check out your very own toplists</li>
                        <li>Premium Content</li>
                        <li>Oh so much more to come!</li>
                        <li>Why wait? Sign me up dudes!</li>
                    </ul>
                </div>
                <div className="col-sm-3 col-12">
                    <h2>Our Partners</h2>
                    <AdListing url={"/video/"} />                                  
                </div>
            </div>
        </div>
    );   
}

export { SignUp_Page }