import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import PlaylistService from "../services/Playlist.service"
import VideoService from "../services/Video.service"
import { VideoView } from "../components/VideoView";
import { AdListing } from "../components/AdListing";
import toast, { Toaster } from 'react-hot-toast';
import authService from './../components/api-authorization/AuthorizeService';

function Playlist_Page(props)
{
    const params = useParams();

    const [vidPlayer, setVidPlayer] = useState([]);
    const [authenticated, setAuthenticated] = useState([false]);
    const [activeUser, setActiveUser] = useState([]);
    const [playlist, setPlaylist] = useState([]);
    const [loadingPlaylist, setLoadingPlaylist] = useState(true);
    const [movies, setMovies] = useState([]);
    const [loadingMovies, setLoadingMovies] = useState(true);

    useEffect(() => {
        populateState();
        loadPlaylistInfo();
    }, []);

    async function populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        if (user) {            
            setActiveUser(user.name);
            setAuthenticated(isAuthenticated);
        }
    }

    async function loadPlaylistInfo() {
        const playlistMovieData = await VideoService.getByPlaylistId(params.playlistid);
        setMovies(playlistMovieData);
        setLoadingMovies(false);

        const data = await PlaylistService.get(params.playlistid);
        setPlaylist(data);
        setLoadingPlaylist(false);

        if (params.videoid == "" || params.videoid == undefined) {
            if (playlistMovieData != "" || playlistMovieData != undefined) {
               showVideo(playlistMovieData[0].wmcExternalId);
            }            
        } else {
            showVideo(params.videoid);
        }
    }

    function showVideo(videoId) {
        setVidPlayer(<VideoView videoId={videoId} />)
    }

    function renderPlaylistView(activePlaylist) {
        renderMoviesTable(movies)
        return (<div>
            <h2>{activePlaylist.name}</h2>
        </div>);
    }

    async function removeFromPlaylist(playlistId, videoId) {
        if (!window.confirm("Are you sure you want to remove this video from the playlist?")) {
            return false;
        }
        const token = await authService.getAccessToken();
        await PlaylistService.removeVideo(playlistId, videoId, token);
        loadPlaylistInfo();
        toast("Video removed from your playlist!")
    }

    function renderMoviesTable(movies, authenticated) {
        return (
            <div className="row">
                {movies.map(movie =>
                    <div className="col-6" key={"movieHolder_" + movie.wmcExternalId}>

                        <div className="divMovieListing" key={"movieCard_" + movie.wmcExternalId}>
                            <a onClick={() => showVideo(movie.wmcExternalId)} className="cursor">
                                <img title={movie.title} alt={movie.title} src={movie.thumbnail} id={"thumb_" + movie.wmcExternalId} className="imgThumb" />
                            </a>
                            <div className="divMovieListingInfo">
                                {authenticated === true ? <div className="row">
                                    <div className="col-9"><a onClick={() => showVideo(movie.wmcExternalId)} className="cursor">{movie.title}</a></div>
                                    <div className="col-3 button"><button className="btn-primary" onClick={() => removeFromPlaylist(params.playlistid, movie.id)}>X</button></div>
                                </div> : <div className="row">
                                        <div className="col-12"><a onClick={() => showVideo(movie.wmcExternalId)} className="cursor">{movie.title}</a></div>
                                </div> }
                            </div>
                        </div>

                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-sm-6 col-12">                
                {vidPlayer}
            </div>
            <div className="col-sm-3 col-6">
                {loadingPlaylist ? <p><em>Loading selected playlist...</em></p> : renderPlaylistView(playlist)}
                {loadingMovies ? <p><em>Loading Videos from the selected playlist...</em></p> : movies.length === 0 ? <strong>No videos added...yet ;)</strong> : renderMoviesTable(movies, authenticated)}
            </div>
            <div className="col-sm-3 col-6">
                <h2>Our Partners</h2>
                <AdListing url={"/video/" + params.videoId} />
            </div>
            <Toaster />
        </div>
    );
}

export { Playlist_Page };