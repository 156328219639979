class ActorDataService {
    async getAll() {
        const response = await fetch('/api/actor/');
        return await response.json();
    }

    async getSelection(amount = 1) {
        const response = await fetch('/api/actor/getSelection?amount=' + amount);
        return await response.json();
    }

    async get(id) {
        const response = await fetch("/api/actor/" + id);
        return await response.json();
    }

    async getActorByName(name) {
        const response = await fetch("/api/actor/getactorbyname?actorname=" + name);
        return await response.json();
    }
}

export default new ActorDataService();