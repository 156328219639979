import React from 'react';
import { triggerCookieConsentBanner, CookieConsentBanner } from "@porscheofficial/cookie-consent-banner-react";

function CookiePolicy_Page(props)
{
    function triggerCookieModal(e) {
        e.preventDefault();
        triggerCookieConsentBanner({ showDetails: false })
    }

    return (
        <div className="row">
            <div className="col-12">
                <h2>Cookie Policy</h2>
                <p>Last Modified 2022-11-13</p>
                Prohibitus Ltd (hereinafter "we", "us" or "our") respects the privacy of visitors to its Website (https://www.prohibitus.com/ or "The Site""). This Cookies Policy (the "Policy"), describes the information that we collect by automated means through the use of automated information-gathering tools, such as cookies and web beacons, as well as your rights to control our use of them.

                <h3>Cookies</h3>
                Cookies are small pieces of information or text that are issued to your computer when you visit a website and are used to store or track information about your use of the site. Cookies are widely used by online service providers to facilitate and help to make the interaction between users and websites, mobile apps, and online platforms faster and easier, as well as to provide reporting information.

                <h3>Our use of cookies</h3>                
                We use the following cookies:
                <ul>
                    <li>Strictly necessary cookies. These are cookies that are required for the operation of our Site. These cookies enable core functionality. They cannot be turned off.</li>
                    <li>Analytics cookies. These allow us to recognize and count the number of visitors and to see how visitors move around our Site. This helps us to improve the way our Site works, for example, by ensuring that users are finding what they are looking for easily.</li>
                    <li>Functionality or performance cookies. These cookies are needed to provide the performance and functionality of our Site and monitor system availability, stability, and error reporting. These are non-essential cookies but if disabled certain functionalities will become unavailable to you. These cookies are used to recognize you when you return to our Site which enables us to personalize our content for you.</li>
                </ul>

                <h3>Your choices and controlling cookies</h3>
                You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences in the cookie modal popup, which can be manually accessed <a href="#" onClick={(e) => triggerCookieModal(e)}>
                    here
                </a>.
                <br /><br />


                You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our Site though your access to some functionality and areas may be restricted. You can refuse cookies through your web browser controls. Different browsers make different controls available to you, you should visit your browser's help menu for more information. Disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser.
                If you use more devices and/or browsers you will need to disable cookies on each device and on each browser separately.
                Find out how to manage and disable cookies on popular browsers:
                <br/><br/>
                <ul>
                    <li><a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en">Google Chrome</a></li>
                    <li><a href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy">Microsoft Edge</a></li>
                    <li><a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">Mozilla Firefox</a></li>
                    <li><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
                    <li><a href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac">Apple Safari</a></li>
                </ul>

                <h3>Updates to this Cookie Policy</h3>
                We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
            </div>
        </div>
    );
}

export { CookiePolicy_Page }