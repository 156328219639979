import React, { useEffect, useState } from 'react';
import authService from './api-authorization/AuthorizeService'
import { useParams } from "react-router-dom";
import { ClockFill, EyeFill } from 'react-bootstrap-icons';
import { Spinner } from 'reactstrap';
import { AdListing } from './AdListing';
import VideoService from "../services/Video.service";

function VideoListing(props)
{
    const params = useParams(); 
    const [movies, setMovies] = useState([]);
    const [loadingMovies, setLoadingMovies] = useState(true);
    const [width, setWidth] = useState (window.innerWidth);

    useEffect(() => {
        loadMovies(props.listingType, props.listingFilter, props.listingAmount, props.listingPage);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const isMobile = width <= 768;

    async function loadMovies(listingType, listingFilter, listingAmount, listingPage = 0) {
        const token = await authService.getAccessToken();
        const data = await VideoService.getMovies(listingType, listingFilter, listingAmount, listingPage, token);
        setMovies(data);
        setLoadingMovies(false);
    }

    function renderVideo(movie, count) {
        if (isMobile) {
            if (count % 4 === 2) {
                return <AdListing amount={1} />
            }
            else {
                return <div className="divMovieListing" key={"movieCard_" + movie.id}>
                    <a href={"/video/" + movie.wmcExternalId + "/" + movie.title} key={"movieHolderLink_" + movie.id}>
                        <img alt={movie.title} src={movie.thumbnail} id={"thumb_" + movie.wmcExternalId} key={"movieHolderImage_" + movie.id} className="imgThumb" />
                    </a>
                    <div className="divMovieListingInfo" key={"movieHolderInfo_" + movie.id}>
                        <a href={"/video/" + movie.wmcExternalId + "/" + movie.title} key={"movieHolderInfoLink_" + movie.id}>{movie.title}</a>
                        {props.listingType !== "relatedvideos" ? <div className="videoInfo" key={"movieHolderVideoInfo_" + movie.id}>
                            <div className="videoViews" key={"movieHolderVideoInfoViews_" + movie.id}><EyeFill className="iconDark" key={"movieHolderVideoInfoViewIcon_" + movie.id} /> {movie.movieStats.length}</div>
                            <div className="videoLength" key={"movieHolderVideoInfoLength_" + movie.id}><ClockFill className="iconDark" key={"movieHolderVideoInfoLengthIcon_" + movie.id} /> {movie.length}</div>
                        </div> : ""}
                    </div>
                </div>
            }
        } else {
            return <div className="divMovieListing" key={"movieCard_" + movie.id}>
                <a href={"/video/" + movie.wmcExternalId + "/" + movie.title} key={"movieHolderLink_" + movie.id}>
                    <img alt={movie.title} src={movie.thumbnail} id={"thumb_" + movie.wmcExternalId} key={"movieHolderImage_" + movie.id} className="imgThumb" />
                </a>
                <div className="divMovieListingInfo" key={"movieHolderInfo_" + movie.id}>
                    <a href={"/video/" + movie.wmcExternalId + "/" + movie.title} key={"movieHolderInfoLink_" + movie.id}>{movie.title}</a>
                    {props.listingType !== "relatedvideos" ? <div className="videoInfo" key={"movieHolderVideoInfo_" + movie.id}>
                        <div className="videoViews" key={"movieHolderVideoInfoViews_" + movie.id}><EyeFill className="iconDark" key={"movieHolderVideoInfoViewIcon_" + movie.id} /> {movie.movieStats.length}</div>
                        <div className="videoLength" key={"movieHolderVideoInfoLength_" + movie.id}><ClockFill className="iconDark" key={"movieHolderVideoInfoLengthIcon_" + movie.id} /> {movie.length}</div>
                    </div> : ""}
                </div>
            </div>
        }      
    }

    function renderMoviesTable(movies, listingCols = 3) {
        return (           
                <div className="row">
                {movies.map((movie, count) =>
                    <div className={"col-sm-" + listingCols + " col-12"} key={"movieHolder_" + movie.id}>
                        {renderVideo(movie,count)}    
                    </div>
                    )}
                </div>
               );
    }

    return (
        <div>
            {loadingMovies ? <span><Spinner className="sm"/> Loading Videos...</span> : movies.length===0 ? "No more more vids here...for now!" : renderMoviesTable(movies, props.listingCols)}                
        </div>
    );
}

export { VideoListing }