import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import ActorService from "../services/Actor.service"
import { VideoListing } from "../components/VideoListing";
import { AdListing } from "../components/AdListing";

function Actor_Page (props)
{
    const params = useParams();
    const [actor, setActor] = useState([]);
    const [loadingActor, setLoadingActor] = useState(true);

    useEffect(() => {
        loadActor();
    }, []);

    async function loadActor() {
        var tmpActor = await ActorService.getActorByName(params.actorName)
        setActor(tmpActor);
        setLoadingActor(false);
    }

    return (
        <div className="row">
            <div className="col-9">
                 <h2>{params.actorName}</h2>
                <p>Here is a list of videos with the great {params.actorName}!</p>
                {loadingActor ?
                <p><em>Loading videos with starring {params.actorName}...</em></p> :
                <VideoListing listingType="actorlisting" listingFilter={actor.wmcExternalId} listingAmount={5} listingCols={3} />}
            </div>
            <div className="col-3">
                <h2>Our Partners</h2>
                <AdListing url={"/video/"} />
            </div>            
        </div>
    );
}

export { Actor_Page };