import React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';

function Layout(props) {
    return (
      <div>
        <Header />
        <div className="mainContainer">
            {props.children}
        </div>
        <Footer />
      </div>
    );
}

export { Layout }
