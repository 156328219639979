import React, { useEffect, useState } from 'react';
import SearchService from './../../services/Search.service';

function Search (props) {

    const [searchCriteria, setSearchCriteria] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [loadingSearchResults, setLoadingSearchResults] = useState(true);

    useEffect(() => {}, []);

    async function loadSearch(strSearch) {
        setSearchCriteria(strSearch);
        if (strSearch.length > 0) {
            const data = await SearchService.get(strSearch);
            console.log(data)
            setSearchResults(data);
            setLoadingSearchResults(false);
        }
    }

    function renderSearchResultItem(searchResult) {
            return (<div className="searchResult"><a 
                            href={searchResult.url} 
                            key={"searchLink_" + searchResult.id}
                            >{searchResult.title}</a>
                         <br />
                    </div>);
    }

    function renderSearchResults(activeSearchResults) {
        return (<div className="divSearchResults">
            <strong className="searchTitle">Search Results ({activeSearchResults.length})</strong><br />
            <strong className="searchTitle">Stars ({activeSearchResults.filter(x => x.type === 'actor').length})</strong>
                            {activeSearchResults.filter(x=>x.type==='actor').map(searchResult => renderSearchResultItem(searchResult))}
                      
            <strong className="searchTitle">Videos ({activeSearchResults.filter(x => x.type === 'video').length})</strong>
                            {activeSearchResults.filter(x => x.type === 'video').map(searchResult => renderSearchResultItem(searchResult))}
                        
            <strong className="searchTitle">Categories ({activeSearchResults.filter(x => x.type === 'category').length})</strong>
                            {activeSearchResults.filter(x => x.type === 'category').map(searchResult => renderSearchResultItem(searchResult))}
                                        
                </div>);
    }

    return (
        <form className="d-flex headerSearch">
            <input className="form-control navSearch" value={searchCriteria} type="search" placeholder="Search" onChange={e => loadSearch(e.target.value)} aria-label="Search" />
            {loadingSearchResults ? "" : renderSearchResults(searchResults)}
        </form>
    );
}

export { Search }