import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { VideoView } from "../components/VideoView";
import { VideoListing } from "../components/VideoListing";
import { AdListing } from "../components/AdListing";

function Video_Page(props)
{
    const params = useParams();
    const [vidPlayer, setVidPlayer] = useState([]);

    useEffect(() => {
        showVideo(params.videoId);
    }, []);

    function showVideo(videoId) {
        setVidPlayer(<VideoView videoId={videoId} />)
    }

    return (
        <div>
            <div className="row">
                <div className="col-sm-6 col-12">
                    {vidPlayer}
                </div>
                <div className="col-sm-3 col-12">
                    <h2>Related Videos</h2>
                    <VideoListing listingType="relatedvideos" listingFilter={params.videoId} listingAmount={6} listingCols={6} />
                </div>
                <div className="col-sm-3 col-12">                    
                    <h2>Our Partners</h2>
                    <AdListing url={"/video/" + params.videoId} />
                </div>
            </div>            
      </div>
    );
}

export { Video_Page };